import { Module } from "vuex";
import {Address, UserState} from "@/store/user/types";
import { RootState } from "../types";
import axios from "axios";
import router from "@/router";

export const User:Module<UserState, RootState> = {
  state: {
    email: "",
    userId: -1,
    name:"",
    isEditAllowed: false,
    isConfirmedEmail:true,
    user: {
      name:'',
      lastname:'',
      company: {
        name:'',
        id: null,
        address_id:null,
        shipping_account_id:null,
        shipping_accounts:[],
        default_address_id:null,
        currency: {
          id: null,
          name: null
        },
        tax: null
      }
    },
    addresses:[],
    shippingAgents:[]
  },
  mutations: {
    updateUserData(state,user){
      state.email = user.email;
      state.userId = user.id;
      state.name = user.name;
      state.user = user;
      state.isEditAllowed = user.company_role == 'edit';
      state.isConfirmedEmail = user.isConfirmedEmail;
      if(!state.isConfirmedEmail) {
        router.push('/confirmEmail')
      }
    },
    updateAgents(state,agents){
      if(Array.isArray(agents)) {
        state.shippingAgents = agents
      }
      // state.shippingAgents = agents;
    },
    updateAddressesList(state:UserState, addresses:Address[]){
      if(!Array.isArray(addresses)) return
      state.addresses = addresses;
    },
    changeCompanyAddressId(state:UserState, address_id){
      state.user!.company!.default_address_id = address_id
    },
    changeCompanyShippingAccount(state:UserState, shipping_account){
      state.user!.company!.shipping_account_id = shipping_account
    },
    updateCompanyCurrency(state, currency){
      state.user!.company!.currency = {id: currency.id, name: currency.name};
    },
    updateCompanyTax(state, tax: number){
      state.user!.company!.tax = tax;
    }
  },
  actions: {
    async loadShippingAgents({commit}){
      const res = await axios.get('/api/shippingAgents');
      commit('updateAgents',res.data);
    },
    async addNewShippingAccount({dispatch},data){
      const res = await axios.post('/api/company/addShippingAccount',data);
      dispatch('loadShippingAgents');
      dispatch('loadUserData');
      return res.data;
    },
    async editShippingAccount({dispatch},data){
      const res = await axios.post('/api/company/updateShippingAccount',data);
      dispatch('loadShippingAgents');
      dispatch('loadUserData');
      return res.data;
    },
    async loadUserData({ commit, rootState, dispatch }){
      try {
        const res = await axios.get(rootState.apiUri+`/api/user`,{
        });
        if(res.data.error){
          console.error(`Not load user data`);
        } else {
          commit('updateUserData',res.data.user);
          await dispatch('LocalStorage/saveUserId',res.data.user.id,{root:true});
          dispatch('loadCompanyCurrency');
          dispatch('loadFixedCompanySettings');
          dispatch('PreOrderCart/loadPreOrderState',null,{root:true});
          // dispatch('loadFixedCompanySettings');
        }
        dispatch('downloadCategories');
      } catch (e) {
        console.error(`Not load user data`);
      }
    },
    async loadAddressesList({commit}){
      const res = await axios.get('/api/addresses');
      commit('updateAddressesList', res.data);
    },
    async addNewAddress({dispatch},addressData){
      const res = await axios.post('/api/addresses',addressData);
      dispatch('loadAddressesList');
      return res.data;
    },
    async editAddress({dispatch},addressData){
      const res = await axios.put(`/api/addresses/${addressData.id}`,addressData);
      dispatch('loadAddressesList');
      return res.data;
    },
    async loadCompanyCurrency({commit, state, rootState}, user){
      const res = await axios.get('/api/currency');
      let currency_id = 2; // USD is default
      let tax_value = null;
      // console.log(rootState)
      const defaultAddress = state.addresses.find(el => el.id == state.user.company.address_id)
      switch (defaultAddress?.country_code?.toUpperCase()) {
        case "CA":
          currency_id = 1;
          tax_value = 13; break;
        case "FR": currency_id = 3; break;
        default: currency_id = 2;
      }
      if(Array.isArray(res.data)) {
        const currency = res.data.find((el: { id: number; }) => el.id == currency_id);
        if(currency) {
          commit('updateCompanyCurrency', currency);
        }
      }
      // const data = res.data!.find((el: { id: number; }) => el.id == currency_id);
      // commit('updateCompanyCurrency', data);
      commit('updateCompanyTax', tax_value);
    },
  },
  getters: {
    ShippingAccounts(state){
      return state.user?.company?.shipping_accounts
    },
    ShippingAgents(state){
      return state.shippingAgents;
    },
    AddressesList(state:UserState){
      return state.addresses
    },
    isConfirmedEmail(state){
      return state.isConfirmedEmail
    },
    isAdminUser(state){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return state.user?.role == 'admin';
    },
    isModeratorUser(state){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return state.user?.role == 'moderator';
    },
    isEditAllowed(state){
      return state.isEditAllowed
    },
    getUserInfo(state){
        return state;
    },
    getAddressId(state){
      return state.user!.company!.default_address_id
    },
    getShippingAccount(state){
      return state.user!.company!.shipping_account_id
    },
    getCompanyCurrency(state){
      // console.log(state.user!.company)
      return state.user!.company!.currency;
    }
  }
}
