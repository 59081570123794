import {Component} from "vue";
import axios from "axios";

async function handleError(error:Error,vm:Component,info:string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const component_name = vm?.$?.type?.name;

  let trace = error.stack ?? 'No stack trace';
  if(trace.length === 0){
    trace = 'No stack trace';
  }

  const data = {
    trace: trace,
    message: error.message ?? 'No message',
    error_name: error.name ?? 'No name',
    location: component_name + ' | ' + info
  }
  axios.post('/api/errorReport/store',data);
}

export {handleError}
