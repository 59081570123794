import {Module} from "vuex";
import {RootState} from "@/store/types";

interface FilesTabState {
  showAutoDetectConfirm:boolean
  showUnzipConfirm:boolean
  newFile: any
  selectedFile:number
  files: any
  groupingMode:boolean
  tempData:any
}

export const FilesTab:Module<FilesTabState, RootState> = {
  namespaced: true,
  state: {
    showAutoDetectConfirm:false,
    showUnzipConfirm:false,
    newFile: [],
    selectedFile:-1,
    files:[],
    groupingMode:true,
    tempData:{}
  },
  getters: {
    isValidated(state){
      if(state.files.length === 0){
        return false;
      }
      return state.files.filter((el:any)=>el.type_id==1).length === 0;
    },
    isSomethingChanged(state,getters,rootState,rootGetters) {
      const productFiles = rootGetters.productFiles;
      for (let i = 0; i < state.files.length; i++) {
        const fileState = productFiles[i];
        const file = state.files[i];
        if(
          file.description !== fileState.description ||
          file.type_id !== fileState.type_id
        ){
          return true;
        }
      }
      return false;
    }
  },
  mutations: {
    updateData(state,productFiles){
      state.files = [];
      for (let i = 0; i < productFiles.length; i++) {
        const file = productFiles[i];
        state.files.push({
          id:file.id,
          name:file.name,
          description:file.description,
          downloadLink:file.downloadLink,
          link:file.link,
          size:file.size,
          isSmallSize:file.isSmallSize,
          guess:file.guess,
          type_id:file.type_id,
        })
      }
    },
  }
}
