import store from "@/store";
import {ref, Ref} from "vue";
import {BufferFile, FormatedFile, File} from "@/store/products/BoardViewer/types";
import {formatBoardFiles, getFormatedFilesForBoardRender} from "@/store/products/BoardViewer/FormatBoardFiles";
import {syncBoardFilesBuffer} from "@/store/products/BoardViewer/SyncBoardFilesBuffer";
import {getLayers} from "@/store/products/BoardViewer/GetLayers";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {pcbStackup} from "@/assets/js/pcb-stackup.min";

const boardSizes = ref({
  width: 0,
  height: 0,
  units: '',
});

export async function calculateBoardSizeFromFiles() {
  const boardFiles:File[] = store.getters.boardFilesForParser.filter((file:File)=>{
    return !(file.file_type == 'otherAttachment' ||
      file.file.size == "0" ||
      file.file_type == 'initialType');
  });

  const formatedFiles:FormatedFile[] = formatBoardFiles(boardFiles);
  await syncBoardFilesBuffer(boardFiles,formatedFiles);
  const layers = getLayers(formatedFiles);
  const stackup = await pcbStackup(layers, {outlineGapFill:1,});

  if(stackup.top) {
    boardSizes.value = {
      width: stackup.top.width,
      height: stackup.top.height,
      units: stackup.top.units,
    }
    return
  }
  if (stackup.bottom) {
    boardSizes.value = {
      width: stackup.bottom.width,
      height: stackup.bottom.height,
      units: stackup.bottom.units,
    }
    return
  }
  return console.error('No stackup');
}

export function getBoardSizeFromFiles(units:string):Ref<{width:number,height:number,units:string}> {
  if(units !== 'in' && units !== 'mm') console.error('units must be in or mm');
  if(boardSizes.value.units !== units) {
    boardSizes.value = {
      width: convert(units, boardSizes.value.units, boardSizes.value.width),
      height: convert(units, boardSizes.value.units, boardSizes.value.height),
      units: units,
    }
  }
  return boardSizes;
}
const convert = (to: string, from: string, value: number) => {
  if (to === 'mm') return from === 'in' ? convertInchesToMM(value) : value;
  if (to === 'in') return from === 'mm' ? convertMMToInches(value) : value;
  return value;
};

const convertMMToInches = (mm:number) => mm/25.4;
const convertInchesToMM = (inches:number) => inches*25.4;
