import {h} from "vue";
import ToastBlock from "@/ui/ToastBlock.vue";

let toast:any = null;

export function setupToastWrapper(toastObj:any) {
  toast = toastObj;
}

export function useToastWrapper() {
  return {
    ...toast,
    init: (args:any) => {
      return toast.init({
        color: '#ffffff',
        render:() => {
          return h(ToastBlock,{...args})
        }
      });
    }
  };
}
