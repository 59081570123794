import {PartCategoryTab} from "@/components/ExperimParts/ResistanceTabFilters/ResistanceTable";

const capacitorTable:PartCategoryTab = {
  columns:[
    { key: "manufacturer", label: "Manufacturer", sortable: false, width: 180  },
    { key: "part_number", label: "Part Number", sortable: false,thAlign: "start", width: 200  },
    { key: "capacitance", label: "Capacitance", sortable: false, width: 120  },
    { key: "voltage", label: "Voltage", sortable: false, width: 120  },
    { key: "dielectric", label: "Dielectric", sortable: false, width: 120 },
    { key: "tolerance", label: "Tolerance", sortable: false, width: 120  },
    { key: "package", label: "Package", sortable: false, width: 200  },
    { key: "qty", label: "Qty on Hand", sortable: false },
    { key: "locs", label: "Locations", sortable: false },
    { key: "price", label: "Price", sortable: false },
  ],
  packages_ids:[42,43,82]
}

export {capacitorTable}
