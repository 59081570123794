import {FormatedFile,File} from "@/store/products/BoardViewer/types";

export function hexToRgb(str: string) {
  const num = parseInt(str.slice(1), 16); // Convert to a number
  return 'rgba('+[num >> 16 & 255, num >> 8 & 255, num & 255, 0.75].join(',')+')';
}

function getPosType(file:File):{pos:string,type:string} {
  let pos = '';
  let type = '';

  if(file.file_type == 'outline' || file.file_type == 'routing'){
    pos = 'all';
    type = 'outline'
  }
  if(file.file_type == 'topSilk' || file.file_type == 'bottomSilk'){
    type = 'silkscreen';
  }
  if(file.file_type == 'topPaste' || file.file_type == 'bottomPaste'){
    type = 'solderpaste';
  }
  if(file.file_type == 'topMask' || file.file_type == 'bottomMask'){
    type = 'soldermask';
  }
  if(file.file_type == 'topCopper' || file.file_type == 'bottomCopper'){
    type = 'copper';
  }
  if(['topSilk','topPaste','topMask','topCopper'].includes(file.file_type)){
    pos = 'top';
  }
  if(['bottomCopper','bottomMask','bottomSilk','bottomPaste'].includes(file.file_type)){
    pos = 'bottom';
  }
  if(file.file_type == 'internalLayer'){
    pos = 'inner';
    type = 'copper'
  }
  if(file.file_type == 'platedDrill'){
    pos = 'all';
    type = 'drill';
  }
  if(file.file_type == 'nonPlatedDrill'){
    pos = 'all';
    type = 'drill';
  }
  return {pos,type}
}

export function getFormatedFilesForBoardRender(files:File[]):FormatedFile[] {
  const filteredFiles:File[] = files.filter((file:File) => {
    if(!file.isVisible ||
      file.file_type == 'otherAttachment' ||
      file.file.size == "0" ||
      file.file_type == 'initialType')
    {
      return false
    }
    return true
  });
  return formatBoardFiles(filteredFiles);
}

/**
 * Formats the board files for rendering.
 *
 * side: 'top' | 'bottom' | 'inner' | 'all' | null,
 * type: 'copper' | 'soldermask' | 'silkscreen' | 'solderpaste' | 'drill' | 'outline' | 'drawing' | null,
 *
 * @param {array} files - The array of files to format.
 * @return {array} The formatted final files array.
 */
export function formatBoardFiles(files:File[]):FormatedFile[] {
  const formatedFiles:FormatedFile[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    const {pos,type} = getPosType(file);
    const color = hexToRgb(file.color);

    if(type === '' && pos === ''){
      console.error('Something went wrong with side parser',file,pos,type);
      continue
    }
    formatedFiles.push({
      id:file.id,
      path:`${file.file.link}`, // FIND ME http://localhost:8000
      pos,
      color,
      type,
      isVisible:file.isVisible,
      layer_number:file.layer_number,
      file_type:file.file_type,
    });
  }
  return formatedFiles
}
