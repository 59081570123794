import axios from "axios";
import store from "@/store";
import router from "@/router";

function setupAxiosInterceptorsRequest() {
  axios.interceptors.request.use(
    function(successfulReq) {
      successfulReq.headers!.Authorization = `Bearer ${store.state.token}`;
      successfulReq.headers!["Accept"] = "application/json";
      return successfulReq;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
}

function setupAxiosInterceptorsResponse(toast:any) {
  axios.interceptors.response.use(
    function(successRes) {
      if(successRes.data?.error){
        if(successRes.data.disabledAccountError){
          router.push('/')
          store.dispatch('logout',true);
        }
        if(successRes.data.error == "Token is Expired" || successRes.data.error == "Token is Invalid" || successRes.data.message == 'Token has Expired'){
          console.log('should be logout')
          store.dispatch('logout',true);
          toast.closeAll();
          toast.init({ message: 'You were logged out due to inactivity. Please log in again', color: 'danger', offsetY: 100, })
        } else {
          let errorMsg = '';
          if(typeof successRes.data?.error == 'object'){
            for (let i = 0; i < Object.keys(successRes.data.error).length; i++) {
              errorMsg += Object.values(successRes.data.error)[i]+'<br>';
            }
          } else {
            errorMsg = successRes.data.message
          }
          // console.log(store.getters.isAuth)
          if(store.getters.isAuth){
            toast.closeAll();
            toast.init({ message:errorMsg , color: 'danger', offsetY: 100, dangerouslyUseHtmlString: true, })
          }
        }
      }
      return successRes;
    },
    function(error) {
      if(error.request.status === 401){
        console.log('should be logout')
        store.dispatch('logout',true);
        toast.closeAll();
        toast.init({ message: 'You were logged out due to inactivity. Please log in again', color: 'danger', offsetY: 100, })
        return Promise.reject(error);
      }
      if(error.request.status === 404){
        toast.init({ message: 'Not found', color: 'danger', offsetY: 100, })
        return Promise.reject(error);
        // router.push('/projects') // change to toast in store
      }
      if(error.response?.data?.message && error.code !== "ERR_BAD_RESPONSE"){
        toast.closeAll();
        toast.init({ message: error.response.data.message, color: 'danger', offsetY: 100, })
        return Promise.reject(error);
      }
      if(error.code === "ERR_BAD_RESPONSE") {
        console.error(error);
      }
      return Promise.reject(error);
    }
  );
}

export {setupAxiosInterceptorsRequest, setupAxiosInterceptorsResponse}
