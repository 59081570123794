import {RootState} from "@/store/types";
import {PlacementTabState} from "@/store/products/PlacementTab/types";
import {Module} from "vuex";

export const PlacementTab:Module<PlacementTabState, RootState> = {
  namespaced: true,
  state: {
    selectedPlacementRows:[],
    selectedFilterPackage:0,
    selectedFilterSide:0,
    selectedFilterConfirmed:0,
    filterValue:'',
    isUnconfirmedOnTheTop:false
  },
  getters: {
    selectedRows(state) {
      return state.selectedPlacementRows;
    },
    filteredPlaces(state, getters, rootState, rootGetters) {
      let ret = rootGetters.placements.filter((el:any)=>{
        let rule1 = true;
        let rule2 = true;
        let rule3 = true;
        let rule4 = true;

        if(state.filterValue !== ''){
          rule1 = el.refDesign.toLowerCase().includes(state.filterValue.toLowerCase())
        }

        if(state.selectedFilterSide != 0) {
          if(state.selectedFilterSide == 1) {
            rule2 = el.side.toLowerCase() == 'top'
          }
          if(state.selectedFilterSide == 2) {
            rule2 = el.side.toLowerCase() == 'bottom'
          }
        }

        if(!state.isUnconfirmedOnTheTop) {
          if(state.selectedFilterConfirmed != 0) {
            if(state.selectedFilterConfirmed == 1) {
              rule3 = el.confirmed
            }
            if(state.selectedFilterConfirmed == 2) {
              rule3 = !el.confirmed
            }
          }
        }


        if(state.selectedFilterPackage != 0) {
          if(state.selectedFilterPackage == 1) {
            rule4 = el.package
          }
          if(state.selectedFilterPackage == 2) {
            rule4 = !el.package
          }
        }
        return rule1 && rule2 && rule3 && rule4;
      });
      if(state.isUnconfirmedOnTheTop) {
        ret = ret.sort((a:any,b:any)=>a.confirmed-b.confirmed);
      }
      return ret;
    }
  },
  mutations: {
    clearSelectedRows(state) {
      state.selectedPlacementRows = [];
    },
    clearFilters(state) {
      state.selectedFilterPackage = 0;
      state.selectedFilterSide = 0;
      state.selectedFilterConfirmed = 0;
      state.filterValue = '';
      state.isUnconfirmedOnTheTop = false;
    },
    selectRow(state, id) {
      if(state.selectedPlacementRows.includes(id)){
        state.selectedPlacementRows = state.selectedPlacementRows.filter(el=>el !== id);
      } else {
        state.selectedPlacementRows.push(id);
      }
    }
  },
  actions: {
    clearState({commit}) {
      commit('clearSelectedRows');
      commit('clearFilters');
    },
    switchAllFilteredRows({state, getters},value:boolean) {
      if(value) {
        state.selectedPlacementRows = getters.filteredPlaces.map((el:any)=>el.id);
      } else {
        state.selectedPlacementRows = [];
      }
    },
  }
}
