
import { createStore, StoreOptions } from 'vuex';
import axios from 'axios';
import router from '../router';
import { RootState} from '@/store/types';
import { User } from './user';
import { Projects } from './projects';

import { Products } from './products';
import {Parts} from "@/store/parts";
import {Issue} from "@/store/products/Issue";
import {Cart} from "@/store/Cart";
import {PreOrderCart} from "@/store/PreOrderCart";
import {Assemblies} from "@/store/projects/assemblies";
import {ProductInfoTab} from "@/store/products/ProductTab";
import {FilesTab} from "@/store/products/FilesTab";
import {BoardTab} from "@/store/products/BoardTab";
import {LocalStorage} from "@/store/LocalStorage";
import {BoardInfo} from "@/store/products/BoardViewer";
import {PartsSearch} from "@/store/PartsSearch";
import {PlacementTab} from "@/store/products/PlacementTab";

const storeOptions:StoreOptions<RootState> = {
  state: {
    isLoggingInProcces: false,
    isLogIn: false,
    apiUri:"",
    loginError: {},
    packagesCategories: [
      {
        name:'No category',
        packages: [
          {
            pkgName: 'Name'
          }
        ]
      }
    ],
    routerHistory:[],
    token: null,
    tokenExpires: null
  },
  getters: {
    routerHistory(state){
      return state.routerHistory;
    },
    packagesCategories(state) {
      return state.packagesCategories;
    },
    isError(state){
      return state.loginError;
    },
    isAuth(state){
      return state.token?true:false;
    },
    isAuthenticated(state){
      return !!state.token;
    },
    getToken(){
      return localStorage.getItem('accessToken');
    }
  },
  mutations: {
    updateRouterHistory(state,newRoute){
      state.routerHistory.push(newRoute)
    },
    changeCategories(state,cats) {
      state.packagesCategories = cats;
    },
    loginStart: state => {
      state.loginError = {};
      state.isLoggingInProcces = true
    },
    loginStop: (state, errorMessage) => {
      state.isLoggingInProcces = false;
      state.loginError = errorMessage;
    },
    updateAccessToken: (state, accessToken) => {
      state.token = accessToken;
      state.isLogIn = true;
    },
    logout: (state) => {
      //console.log(state.isLogIn)
      state.token = null;
      state.isLogIn = false;
      //console.log(state.isLogIn)
    },
  },
  actions: {
    async logout({ commit, dispatch, getters },flag=false) {
      const token = getters.getToken;
      if(token !== null){
        try {
          const res = await axios.get('/api/logout',{
            headers: { Authorization: `Bearer ${token}` },
            params: {
              token
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
      localStorage.removeItem('accessToken');
      dispatch('LocalStorage/saveUserId',-1);
      commit('logout');
      if(flag){
        router.push('/login');
      }
    },
    async downloadCategories({ commit, dispatch }){
      axios.get('/api/getPackages').then(res=>{
        //console.log(res.data)
        commit('changeCategories',res.data.categories);
      });
    },
    async searchMouser({ commit, dispatch },{
      productId,
      searchValue
    }){
      const res = await axios.post(this.state.apiUri+`/api/products/${productId}/bom/prices?word=${encodeURI(searchValue)}`,{
        headers: { Authorization: `Bearer ${this.state.token}` }
      });
      return res;
    },
    async checkIfTokenStillValid({ commit, dispatch, getters },token){
        const res = await axios.get(this.state.apiUri+`/api/user`,{
          headers: { Authorization: `Bearer ${token}` }
      });
      //console.log(res.data);
      if(res.data.error){
        console.log('not logged in')
        // dispatch('logout')
        if(localStorage.getItem('accessToken')){
          router.push('/');
        }
        commit('logout');
        dispatch('LocalStorage/saveUserId',-1);
        return false;
      } else {
        console.log('logged in')
        if(!res.data.user.isConfirmedEmail){
          // router.push('/confirmEmail')
        } else {
          if(router.currentRoute.value.name  == 'confirmEmail'){
            router.push('/projects')
          }
        }
        dispatch('downloadCategories');
        dispatch('loadAddressesList');
        dispatch('loadShippingAgents');
        commit('updateUserData',res.data.user);
        dispatch('LocalStorage/saveUserId',res.data.user.id);
        // commit('changeCompany',res.data.user.company_id)
        dispatch('fetchAccessToken');
        dispatch('loadCompanyCurrency');
        dispatch('loadFixedCompanySettings');
        dispatch('PreOrderCart/loadPreOrderState',null,{root:true});
        return true;
      }
    },
    async doRegister({ commit, dispatch, getters, state }, loginData){
      try {
        commit('loginStart');
        const response = await axios.post(`${this.state.apiUri}/api/register`, {
          ...loginData
        });
        if(!response.data.error){
          if(response.data.isEmailWithConfirmWasSend){
            // useToast().init({ message: 'Please confirm your email before you begin creating new boards',color: 'warning' });
          }
          localStorage.setItem('accessToken', response.data.token);
          commit('loginStop', false);
          commit('updateAccessToken', response.data.token);
          if(getters.routerHistory.length>0){
            router.push(getters.routerHistory[getters.routerHistory.length-1]);
          } else {
            router.push('/workspace')
          }
          dispatch('loadUserData');
        } else {
          commit('loginStop', response.data);
        }
      } catch (error:any) {
        console.log(error)
        commit('loginStop', error.response.data);
        commit('updateAccessToken', null);
      }
    },
    async doLogin({ commit,dispatch, getters }, loginData) {
      commit('loginStart');
      try {
        const response = await axios.post(`${this.state.apiUri}/api/login`, {
          ...loginData
        });
        if(!response.data.error){
          localStorage.setItem('accessToken', response.data.token);
          commit('loginStop', false);
          commit('updateAccessToken', response.data.token);
          await dispatch('loadUserData');
          if(getters.routerHistory.length>0){
            const lastRoute = getters.routerHistory[getters.routerHistory.length-1];
            if(lastRoute == '/login' || lastRoute == ''){
              router.push('/workspace');
            }
            console.log(lastRoute,getters.routerHistory);
            router.push(lastRoute);
          } else {
            router.push('/workspace')
          }
        } else {
          console.log(response.data);
          commit('loginStop', response.data);
        }
      } catch (error: any) {
        commit('loginStop', error.response.data);
        commit('updateAccessToken', null);
      } finally {
        commit('loginStop', 'Something went wrong');
      }
    },
    fetchAccessToken({ commit }) {
      commit('updateAccessToken', localStorage.getItem('accessToken'));
    },
  },
  modules: {
    Parts,
    Assemblies,
    User,
    Projects,
    Products,
    Issue,
    Cart,
    PreOrderCart,
    ProductInfoTab,
    FilesTab,
    BoardTab,
    LocalStorage,
    BoardInfo,
    PartsSearch,
    PlacementTab
  }
}

export default createStore(storeOptions);
