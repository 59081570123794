import {Module} from "vuex";
import {RootState} from "@/store/types";

export const ProductInfoTab:Module<ProductInfoTabState, RootState> = {
  namespaced: true,
  state: {
    name: '',
    partNumber: '',
    visible: true,
    description: '',
    notes: '',
    imgLink: '',
    newCover: [],
    id: null,
    odoo_products: []
  },
  getters: {
    isValidated(state){
      return state.name.length > 0;
    },
    isSomethingChanged(state,getters,rootState,rootGetters) {
      const productInfo = rootGetters.productInfo;
      return state.name !== productInfo?.name
        || state.partNumber !== productInfo?.partNumber
        || state.description !== productInfo?.description
        || state.notes !== productInfo?.notes
        || state.odoo_products !== productInfo?.odoo_products
    }
  },
  mutations: {
    updateData(state,productInfo){
      state.id = productInfo.id;
      state.name = productInfo.name;
      state.partNumber = productInfo.partNumber;
      state.description = productInfo.description;
      state.notes = productInfo.notes;
      state.imgLink = productInfo.imgLink;
      state.visible = productInfo.visible;
      state.odoo_products = productInfo.odoo_products;
    },
  },
}
