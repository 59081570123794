import {Module} from "vuex";
import {RootState} from "@/store/types";

interface LocalStorageState {
  isDebugMode: boolean,
  savedList: string[]
  isItOldLocalStorage: boolean
}

export const LocalStorage:Module<LocalStorageState, RootState> = {
  namespaced: true,
  state: {
    isDebugMode: false,
    savedList:[
      'lastUserId',
      'fixedCompany',
      'wsSettings',
      'preorder_cart',
      'partSearchFilters'
    ],
    isItOldLocalStorage: false
  },
  mutations: {},
  getters: {
    getUserId(state, getters, rootState, rootGetters){
      const userId = rootGetters.getUserInfo.userId
      if (!userId || userId == -1) {
        const lastUserId = localStorage.getItem('lastUserId');
        if(lastUserId){
          return parseFloat(lastUserId);
        } else {
          console.error('There no user id');
        }
      }
      return userId;
    },
    getFixedCompany(state, getters, rootState, rootGetters){
      const fieldNameWithUserId = `fixedCompany_${getters.getUserId}`
      if(state.isDebugMode) console.log(getters.getUserId,localStorage.getItem(fieldNameWithUserId))
      if(getters.getUserId){
        const fromStorageValue = localStorage.getItem(fieldNameWithUserId);
        if(fromStorageValue){
          return parseFloat(fromStorageValue)
        }
      }
      return localStorage.getItem(fieldNameWithUserId) ?? null;
    },
    getWsSettings(state, getters, rootState, rootGetters){
      const fieldNameWithUserId = `wsSettings_${getters.getUserId}`
      if(state.isDebugMode) console.log(getters.getUserId,localStorage.getItem(fieldNameWithUserId))
      const settings = localStorage.getItem(fieldNameWithUserId);
      if(settings){
        return JSON.parse(settings);
      }
      return {};
    },
    getPreOrderCart(state, getters, rootState, rootGetters){
      const fieldNameWithUserId = `preorder_cart_${getters.getUserId}`
      if(state.isDebugMode) console.log(getters.getUserId,localStorage.getItem(fieldNameWithUserId))
      const cart = localStorage.getItem(fieldNameWithUserId);
      if(cart){
        return JSON.parse(cart);
      }
      return [];
    },
    getPartSearchFilters(state, getters, rootState, rootGetters){
      const fieldNameWithUserId = `partSearchFilters_${getters.getUserId}`
      if(state.isDebugMode) console.log(getters.getUserId,localStorage.getItem(fieldNameWithUserId))
      const filters = localStorage.getItem(fieldNameWithUserId);
      if(filters){
        return JSON.parse(filters);
      }
      return {};
    },
    getPreOrderCartTemplates(state, getters, rootState, rootGetters){
      const fieldNameWithUserId = `preCartTemplates_${getters.getUserId}`;
      if(state.isDebugMode) console.log(getters.getUserId,localStorage.getItem(fieldNameWithUserId))
      const templates = localStorage.getItem(fieldNameWithUserId);
      if(templates){
        return JSON.parse(templates);
      }
      return {};
    }
  },
  actions: {
    checkForMigrateFromNonUserData({state, getters, rootGetters}){
      if(state.isDebugMode) console.log(getters.getUserId,localStorage.getItem('lastUserId'))
      if(!localStorage.getItem('preorder_cart_'+getters.getUserId)) {
        state.isItOldLocalStorage = true;
      }
      if(state.isItOldLocalStorage){
        for (let i = 0; i < getters.savedList.length; i++) {
          const fieldName = getters.savedList[i];
          const fromStorageValue = localStorage.getItem(fieldName);
          const userRelatedData = localStorage.getItem(`${fieldName}_${getters.getUserId}`);
          if(fromStorageValue && !userRelatedData){
            if(state.isDebugMode) console.log(getters.getUserId,fieldName,fromStorageValue);
            localStorage.setItem(`${fieldName}_${getters.getUserId}`,fromStorageValue);
            localStorage.removeItem(fieldName);
          }
        }
      }
    },
    saveUserId({state, getters, rootGetters},id){
      if(state.isDebugMode) console.log(getters.getUserId,id)
      localStorage.setItem('lastUserId', id?.toString() ?? '');
    },
    loadFixedCompanySettings({ dispatch, getters,state }){
      const fieldNameWithUserId = `fixedCompany_${getters.getUserId}`;
      if(state.isDebugMode) console.log(getters.getUserId,localStorage.getItem(fieldNameWithUserId))
      const fromStorageValue = localStorage.getItem(fieldNameWithUserId);
      if(fromStorageValue){
        const companyId = parseFloat(fromStorageValue);
        dispatch('changeFixedCompany',companyId)
      }
    },
    changeFixedCompany({state, getters, rootGetters},id){
      const fieldNameWithUserId = `fixedCompany_${getters.getUserId}`;
      if(state.isDebugMode) console.log(getters.getUserId,id)
      localStorage.setItem(fieldNameWithUserId, id?.toString() ?? '');
    },
    saveWsSettings({state, getters, rootGetters},data){
      if(state.isDebugMode) console.log(getters.getUserId,data);
      const fieldNameWithUserId = `wsSettings_${getters.getUserId}`;
      localStorage.setItem(fieldNameWithUserId,JSON.stringify(data));
    },
    savePreOrderCart({state, getters, rootGetters},data){
      if(state.isDebugMode) console.log(getters.getUserId,data);
      const fieldNameWithUserId = `preorder_cart_${getters.getUserId}`;
      localStorage.setItem(fieldNameWithUserId,JSON.stringify(data));
    },
    savePartSearchFilters({state, getters, rootGetters},data){
      if(state.isDebugMode) console.log(getters.getUserId,data);
      const fieldNameWithUserId = `partSearchFilters_${getters.getUserId}`;
      localStorage.setItem(fieldNameWithUserId,JSON.stringify(data));
    },
    savePreCartTemplates({state, getters, rootGetters},data){
      if(state.isDebugMode) console.log(getters.getUserId,data);
      const fieldNameWithUserId = `preCartTemplates_${getters.getUserId}`;
      localStorage.setItem(fieldNameWithUserId,JSON.stringify(data));
    }
  },
}
