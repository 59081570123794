import { Module } from "vuex";
import {RootState} from "../../types";
import axios from "axios";
import {IssueState} from "@/store/products/Issue/types";

const isDebug = true;

const createIssueStepHandler = (tabName:string) => {
  let step = 0;
  const problems: any[] = [];
  return {
    getTabName: () => tabName,
    getStep: () => step,
    createIssue: (message:string,code:number) => {
      step++;
      problems.push({
        step,
        message,
        code
      });
    },
    problems,
  }
}

export const Issue:Module<IssueState, RootState> = {
  state: {
    handlers:[]
  },
  mutations: {},
  getters: {
    productTabsIssues(state, getters){
      return [
        getters.productIssue,
        getters.filesIssue,
        getters.boardIssue,
        getters.bomIssue,
        getters.placementsIssue,
      ]
    },
    productIssue(state,getters){
      const handler = createIssueStepHandler('Product');
      const info = getters.productInfo;
      if(info.name.length == 0){
        handler.createIssue('Product name is not set',1);
      }
      if(info.partNumber.length == 0){
        handler.createIssue('Product part number is empty',2);
      } else {
        if(info.partNumber.length < 3){
          handler.createIssue('Product part number is too short',3);
        }
      }
      return handler;
    },
    filesIssue(state, getters, rootState, rootGetters){
      const handler = createIssueStepHandler('Files');
      const files = rootGetters.productFiles;
      if(isDebug) console.log(files)
      if(files.length == 0){
        handler.createIssue('Product doesn\'t have any files attached',4)
      }

      if (rootGetters.productBoardFiles!.length == 0){
        handler.createIssue('Product doesn\'t have any board files attached',5)
      }

      if (rootGetters.productFiles.filter((el:any)=>el.type_id==4).length == 0){
        handler.createIssue('Product doesn\'t have any BOM files attached',6)
      }

      if (rootGetters.productFiles.filter((el:any)=>el.type_id==5).length == 0){
        handler.createIssue('Product doesn\'t have any Pick & Place files attached',7)
      }

      return handler;
    },
    boardIssue(state, getters){
      const handler = createIssueStepHandler('Board');
      const boardFiles = getters.productBoardFiles;
      if(isDebug) console.log(boardFiles)

      // console.log(getters.boardSettingsOptions.boardFilesOptions,getters.productBoardFiles);

      if(boardFiles.length == 0 ){
        handler.createIssue('Product doesn\'t have any board files attached',8)
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if(boardFiles.filter(el=>el.file_type.includes('top')).length == 0){
        handler.createIssue('Product doesn\'t have any board files attached for top side',9)
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if(boardFiles.filter(el=>el.file_type.includes('bottom')).length == 0){
        handler.createIssue('Product doesn\'t have any board files attached for bottom side',10)
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if(boardFiles.filter(el=>el.file_type.includes('bottom')).length == 0){
        handler.createIssue('Product doesn\'t have any board files attached for outline',11)
      }

      return handler;
    },
    bomIssue(state, getters){
      const handler = createIssueStepHandler('BOM');
      const boms = getters.boms;
      if(isDebug) console.log(boms)

      console.log(getters.issueReportDiffBomPlacement);

      if(boms.length == 0 ){
        handler.createIssue('Product doesn\'t have BOM information',12);
      }

      if(getters.bomInfo.totalAmount !== getters.bomInfo.confirmed){
        handler.createIssue(
          `Product has ${getters.bomInfo.totalAmount-getters.bomInfo.confirmed} unconfirmed part(s)`,
          13
        );
      }

      for (let i = 0; i < getters.issueReportDiffBomPlacement.boms.length; i++) {
        const el = getters.issueReportDiffBomPlacement.boms[i];
        handler.createIssue(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          `${el.refDesign.map(el=>el.refDesign).join(', ')} has no placement information`,
          14
        );
      }

      if(getters.issueReportDiffBomPlacement.boms.length>0){
        handler.createIssue(
          `Product has ${getters.issueReportDiffBomPlacement.boms.length} BOM row(s) without associated placement`,
          15
        );
      }

      let valueArr:any[] = [];
      boms.forEach((item1: { refDesign: { refDesign: any; }[]; }) => {
        valueArr = valueArr.concat(item1.refDesign.map((item2: { refDesign: any; }) => item2.refDesign ));
      });
      const isDuplicate = valueArr.filter((currValue, currIndex) =>
        valueArr.indexOf(currValue) !== currIndex);
      if(isDuplicate.length > 0)
        isDuplicate.forEach(item => {
          handler.createIssue(
            `Product has duplicate BOM row(s) | RefDes: `+item,
            16
          );
        });

      console.log(getters.issueReportDiffBomPlacement)

      return handler;
    },
    placementsIssue(state, getters){
      const handler = createIssueStepHandler('Placement');
      const placements = getters.placements;
      if(isDebug) console.log(placements);

      if(placements.length == 0 ){
        handler.createIssue('Product doesn\'t have Placement information',16)
      }

      if(getters.placementsInfo.totalAmount !== getters.placementsInfo.confirmed){
        handler.createIssue(
          `Product has ${getters.placementsInfo.totalAmount-getters.placementsInfo.confirmed} unconfirmed placement(s)`,
          17
        );
      }

      for (let i = 0; i < getters.issueReportDiffBomPlacement.placements.length; i++) {
        const el = getters.issueReportDiffBomPlacement.placements[i];
        handler.createIssue(
          `${el.refDesign} has no BOM information `,
          18
        );
      }

      if(getters.issueReportDiffBomPlacement.placements.length>0){
        handler.createIssue(
          `Product has ${getters.issueReportDiffBomPlacement.placements.length} placement row(s) without associated BOM row(s) `,
          19
        );
      }

      let valueArr:any[] = [];
      valueArr = placements.map((item2: { refDesign: any; }) => item2.refDesign );

      const isDuplicate = valueArr.filter((currValue, currIndex) =>
        valueArr.indexOf(currValue) !== currIndex);
      if(isDuplicate.length > 0)
        isDuplicate.forEach(item => {
          handler.createIssue(
            `Product has duplicate Placement row(s) | RefDes: `+item,
            20
          );
        });

      return handler;
    },
    issueReportDiffBomPlacement(state,getters){
      const boms_rows = getters.boms;
      const placement_rows = getters.placements;

      const fixRefDesign = (_:string)=>_.replaceAll(' ','').toLowerCase();

      const boms_without_placements = [];
      const placement_rows_without_bom = [];
      for (let i = 0; i < boms_rows!.length; i++) {
        const placements = [];
        let flag = false;
        for (let j = 0; j < boms_rows[i].refDesign.length; j++) {
          const el = boms_rows[i].refDesign[j];

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          placements.push(...placement_rows.filter(e=>fixRefDesign(e.refDesign) === fixRefDesign(el.refDesign)));

          if(placements.length===0){
            flag = true;
          }
        }
        if(flag){
          boms_without_placements.push({
            refDesign:boms_rows[i].refDesign,
            placements:placements,
          });
        }
      }

      for (let i = 0; i < placement_rows.length; i++) {
        const el = placement_rows[i];

        const placement_bom_row = boms_rows.filter(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          row=>
            row.refDesign.find(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref=>
                ref.refDesign ===el.refDesign
            ) !== undefined
        );
        if(placement_bom_row.length === 0){
          placement_rows_without_bom.push({
            placement_bom_row,
            refDesign:el.refDesign,
            el
          });
        }
      }

      return {
        boms:boms_without_placements,
        placements:placement_rows_without_bom,
      };
    },
  },
  actions: {},
}
