import {BufferFile, FormatedFile, ProxyBufferFile, File} from "@/store/products/BoardViewer/types";
import store from "@/store";
import axios from "axios";

const isFileInBuffer = (file:FormatedFile,buffer:BufferFile[]):boolean => {
  for (let i = 0; i < buffer.length; i++) {
    if(buffer[i].file.id === file.id) return true
  }
  return false
}

async function loadFileContent(file:FormatedFile):Promise<ProxyBufferFile|null> {
  // console.time('loadBoardFileContent'+file.id)
  const req = await axios.get(file.path);
  if(req.data.error) {
    return null;
  }
  // console.timeEnd('loadBoardFileContent'+file.id)
  return {
    id:file.id,
    content:req.data,
  };
}

function sync(requiredFiles:FormatedFile[],newFilesContent:ProxyBufferFile[],bufferFiles:BufferFile[],files:File[]) {
  for (let i = 0; i < requiredFiles.length; i++) {
    const required_file = requiredFiles[i];
    const found = bufferFiles.find(el=>el.file.id === required_file.id);

    const file = files.find(el=>el.id === required_file.id);
    if(!file) continue;

    if(!found){
      const loaded = newFilesContent.find(el=>el.id === required_file.id);
      if(!loaded) continue;
      store.commit('BoardInfo/addBufferedFile',{
        file,
        content:loaded.content
      });
    } else {
      found.file = file;
    }
  }
}

export async function syncBoardFilesBuffer(files:File[],requiredFiles:FormatedFile[],){
  const waitingPool:Promise<ProxyBufferFile|null> [] = [];
  const bufferFiles:BufferFile[] = store.getters['BoardInfo/bufferedFiles'].buffer;

  for (let i = 0; i < requiredFiles.length; i++) {
    const file = requiredFiles[i];
    if(isFileInBuffer(file,bufferFiles)) continue;
    waitingPool.push(loadFileContent(file));
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const newFilesContent:ProxyBufferFile[] = (await Promise.all(waitingPool)).filter((el:any) => el !== null);

  sync(requiredFiles,newFilesContent,bufferFiles,files);

  // return newFilesContent.filter((el) => el !== null);
}
