
import store from "@/store";
import {BufferFile, FormatedFile} from "@/store/products/BoardViewer/types";
export function getLayers(files:FormatedFile[]) {
  const buffer = store.getters['BoardInfo/bufferedFiles'].buffer as BufferFile[];
  const layers = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const loaded = buffer.find(el=>el.file.id === file.id);
    if(!loaded) continue;
    const fileContent = loaded.content;
    layers.push({
      gerber:fileContent,
      side:file.pos,
      type:file.type,
      color:loaded.file.color,
      id:file.id,
      file_type:loaded.file.file_type,
    })
  }
  return layers;
}
