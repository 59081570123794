import {Module} from "vuex";
import {RootState} from "@/store/types";
import {CartState} from "@/store/PreOrderCart/types";
import axios, {CancelTokenSource} from "axios";
import {Product} from "@/store/PreOrderCart/types";
import router from "@/router";
import {useToastWrapper} from "@/utils/SetupToastWrapper";

export const PreOrderCart:Module<CartState, RootState> = {
  namespaced: true,
  state: {
    productsList:[],
    showCartBlock:false,
  },
  mutations: {
    addProduct(state:CartState,product:Product){
      state.productsList.push(product);
    },
    removeProductFromCart(state:CartState,id:number){
      state.productsList = state.productsList.filter((el:Product)=>el.id !== id);
    },
    clearCart(state:CartState){
      state.productsList = [];
    },
    updateProductAmount(state:CartState,{product,amount}){
      const index = state.productsList.findIndex((el:Product)=>el.id == product.id);
      state.productsList[index].amount = amount;
    },
  },
  getters: {
    cartProducts(state:CartState){
      return state.productsList;
    }
  },
  actions: {
    addProduct({commit, getters, dispatch},{
      id,title,pn,img, toastContext,preAmount,panelization
    }){
      const product = {
        line:getters.cartProducts.length+1,
        id,
        title,
        pn,
        img,
        panelization,
        amount:preAmount??1,
      }
      const productInCartSearch = getters.cartProducts.find((el:any)=>el.id == product.id);
      dispatch('saveProductToCartDB',{
        product_id:product.id,
        amount:product.amount
      });
      if(productInCartSearch){
        toastContext.init({
          message:'Product in cart already',
          color:'warning'
        });
        dispatch('updateProductAmount',{product:productInCartSearch,amount:product.amount});
      } else {
        commit('addProduct',product);
        dispatch('saveStateLocal');
      }

      //console.log(product);
    },
    saveStateLocal({getters, dispatch}){
      dispatch('LocalStorage/savePreOrderCart',getters.cartProducts,{root:true});
    },
    loadPreOrderState({commit, rootGetters, dispatch,getters}){
      // const data = rootGetters['LocalStorage/getPreOrderCart'];
      // // console.log(data,'loading cart');
      // if(data){
      //   commit('clearCart');
      //   for (let i = 0; i < data.length; i++) {
      //     commit('addProduct',data[i])
      //   }
      // }
      dispatch('loadCartData');
      // if(getters.cartProducts.length>0){
      //   dispatch('loadActualProductDataInCart', getters.cartProducts.map((el:Product)=>{
      //     return {
      //       id:el.id
      //     }
      //   }));
      // }
    },
    async loadCartData({commit,state,rootGetters,getters}){
      try {
        const res = await axios.get('/api/cart');
        console.log(res.data);
        for (let i = 0; i < res.data.length; i++) {
          const prod = res.data[i];
          if(!prod.isValidBoardSettings) {
            // Should remove product from cart
            console.error('invalid board settings');
          }
          // handle new product information
          const productInCart = state.productsList.find((el:Product)=>el.id == prod.product.id);
          if(productInCart){
            productInCart.panelization = prod.panelization;
            productInCart.title = prod.product.name;
            productInCart.pn = prod.product.partNumber;
            if (prod.product.imgLink) {
              productInCart.img = prod.product.imgLink;
            }
          } else {
            commit('addProduct',{
              line:getters.cartProducts.length+1,
              id:prod.product.id,
              title:prod.product.name,
              pn:prod.product.partNumber,
              img:prod.product.imgLink,
              panelization:prod.panelization,
              amount:prod.amount,
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async saveProductToCartDB({commit,rootGetters},data){
      try {
        const res = await axios.post('/api/cart',data);
        if(res.data.status === 'error') {
          commit('removeProductFromCart',data.product_id)
          useToastWrapper().init({
            color:'warning',
            message:res.data.message
          })
        }
      } catch (e) {
        console.error(e);
      }
    },
    async clearCartDB() {
      try {
        const res = await axios.delete('/api/cart');
      } catch (e) {
        console.error(e);
      }
    },
    async removeProductFromCartDB({commit,rootGetters},id){
      try {
        const res = await axios.delete(`/api/cart/${id}`);
      } catch (e) {
        console.error(e);
      }
    },
    async loadActualProductDataInCart({commit,rootGetters, state},products){
      try {
        const res = await axios.post('/api/products/cartData',{
          products
        });
        if(res.data.products){
          for (let i = 0; i < res.data.products.length; i++) {
            const prod = res.data.products[i];
            if(!prod.isValidBoardSettings) {
              // Should remove product from cart
              console.error('invalid board settings');
            }
            // handle new product information
            const productInCart = state.productsList.find((el:Product)=>el.id == prod.product.id);
            if(productInCart){
              productInCart.panelization = prod.panelization;
              productInCart.title = prod.product.name;
              productInCart.pn = prod.product.partNumber;
              if (prod.product.imgLink) {
                productInCart.img = prod.product.imgLink;
              }
            }
          }
          //console.log(state.productsList);
        }
      } catch (e) {
        console.error(e);
      }
    },
    clearCart({commit,getters,dispatch}){
      commit('clearCart');
      dispatch('clearCartDB');
      dispatch('saveStateLocal');
    },
    removeProductFromCart({commit,dispatch},id){
      commit('removeProductFromCart',id);
      dispatch('removeProductFromCartDB',id);
      dispatch('saveStateLocal');
    },
    updateProductAmount({commit,dispatch},data){
      commit('updateProductAmount',data);
      dispatch('saveProductToCartDB',{
        product_id:data.product.id,
        amount:data.amount
      });
      dispatch('saveStateLocal');
    },
    async createPreOrder({getters, commit,dispatch},data){
      try {
        const res = await axios.post('/api/documents/preorder',data);
        router.push(`/createDocument/order/${res.data.doc.id}`);
        dispatch('clearCart')
      } catch (e) {
        console.error(e);
      }
    },
  },

}
