import {Module} from "vuex";
import {RootState} from "@/store/types";
import {CartState, Product} from "@/store/Cart/types";
import axios, {CancelTokenSource} from "axios";
import router from "@/router";
import {computed, ref} from "vue";

export const Cart:Module<CartState, RootState> = {
  namespaced: true,
  state: {
    productsList:[],
    showCartBlock:false,
    getPriceReqCancelToken:null
  },
  mutations: {
    saveState(state:CartState){
      localStorage.setItem('cart-data',JSON.stringify(state));
      console.log('saved',localStorage.getItem('cart-data'));
    },
    updatePrices(state:CartState,newPricesInformation){
      //console.log(newPricesInformation)
      for (let i = 0; i < state.productsList.length; i++) {
        const prod = state.productsList[i];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const related = newPricesInformation.find(el=>el.product_id === prod.id);

        if(related.line_price){
          prod.price = related.line_price;
          prod.otherCurrency = related.other_currency
        } else {
          prod.isNoPrice = true;
        }
        prod.priceDate = new Date();
      }
    },
    clear(state:CartState){
      state.productsList = [];
    },
    changeShowingCart(state:CartState,newValue:boolean){
      state.showCartBlock = newValue;
    },
    startANewCart(state:CartState){
      state.showCartBlock = true;
    },
    removeProduct(state:CartState,id:number){
      state.productsList = state.productsList.filter(el=>el.id !== id)
    },
    addLineNumbers(state:CartState){
      state.productsList.map(function (product:Product,index:number) {
        product.line = index+1;
      });
    },
    addProduct(state:CartState,product:Product){
      state.productsList.push(product);
    },
    updatePriceReqCancelToken(state:CartState,token:CancelTokenSource){
      state.getPriceReqCancelToken = token;
    }
  },
  getters: {
    getPriceReqCancelToken(state:CartState){
      return state.getPriceReqCancelToken;
    },
    productList(state:CartState):Product[]{
      return state.productsList.reverse().map((prod:Product)=>{
        return prod;
      });
    },
    product:(state:CartState) => (id:number) => {
      return state.productsList.find(el=>el.id === id);
    }
  },
  actions: {
    addProduct( {getters, commit, dispatch}, {  product, toastInit, amount }){
      if(getters.productList.find((el:Product) => el.id === product.id) !== undefined) {
        return toastInit({
          message:'Product already in the Cart',
          color:'warning'
        });
      }
      //console.log('custom amount for new row ',amount);
      const amountProxy = ref<number>(amount ?? 1);
      // const timerForUpdate = ref<number>(5);
      commit('addProduct',{
        line:null,
        id:product.id,
        title:product.name,
        pn:product.partNumber,
        img:product.imgLink,
        amountProxy,
        amount:computed({
          get() {
            return amountProxy.value
          },
          set(value:number){
            if(value<1){
              value = 1;
            }
            amountProxy.value = value;
            dispatch('getPriceForBoard');
          }
        }),
        price:null,
        priceDate:new Date(),
        otherCurrency:{
          USD: null,
          CAD: null,
          EUR: null
        },
        isNoPrice:false,
      })
    },

    decreaseAmount({dispatch,commit,getters },product:Product){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      product.amount--;
      if(product.amount <=0) commit('removeProduct',product.id);
      // dispatch('getPriceForBoard',product);
    },
    increaseAmount({dispatch,commit,getters },product:Product){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      product.amount++;
      // dispatch('getPriceForBoard',product);
    },
    addToCart({dispatch,commit,getters },{product,toastInit,amount}){
      if(!product.isValidBoardSettings) {
        return toastInit({
          message:'Is not possible to add to the cart, because board settings are not correct',
          color:'danger'
        });
      }

      if(getters.productList.find((el:Product)=>el.id === product.id)) {
        return toastInit({
          message:'Product already in the Cart',
          color:'warning'
        });
      }
      dispatch('addProduct',{product,toastInit,amount});
      const newProd:Product = getters.product(product.id);
      dispatch('getPriceForBoard')
    },
    async createOrderFromPreOrder({commit},{
      data,
      toastContext
    }){
      const res = await axios.post('/api/documents',{
        ...data,
        type_id:1,
      });
      if(res.data.success){
        if(res.data.payment?.payer_action_url){
          // await commit('clear');
          // await commit('saveState');
          router.push(`/payment/${res.data.output.id}`);
          return;
        }
        toastContext.init({
          color:'success',
          message: res.data.message
        });
        // commit('clear');
        await router.push('orders');
      } else {
        return;
      }
    },
    async createOrder({getters, commit},{
      data,
      toastContext
    }){
      const lines = getters.productList.map((el:Product)=>{
        return {
          line_number: el.line,
          product_id: el.id,
          amount: el.amount
        }
      })
      const res = await axios.post('/api/documents',{
        name:data.name,
        date:data.date,
        type_id:1,
        service_id:data.service_id,
        agent_id:data.agent_id,
        shipping_account_id:data.shipping_account_id,
        company_id:data.company_id,
        address_id:data.address_id,
        currency_id:data.currency_id,
        create_proforma:data.create_proforma,
        lines
      });
      if(res.data.success){
        //console.log(res.data.payment);
        if(res.data.payment?.payer_action_url){
          await commit('clear');
          await commit('saveState');
          router.push(`/payment/${res.data.output.id}`)
          // window.open(
          //   res.data.payment?.payer_action_url,
          //   '_self'
          // );
          return;
        }
        toastContext.init({
          color:'success',
          message: res.data.message
        });
        commit('clear');
        await router.push('orders');
      } else {
        return;
      }
    },
    async getPriceForBoard({ getters, commit}){
      const payload = {
        products_list: getters.productList.map((el:Product)=>{
          el.isNoPrice = false;
          el.price = null;
          el.otherCurrency = {
            CAD:null,
            EUR:null,
            USD:null
          }
          return {
            product_id:el.id,
            amount:el.amount,
          }
        })
      }

      if(getters.getPriceReqCancelToken !== null && getters.getPriceReqCancelToken !== undefined){
        //console.log(getters.getPriceReqCancelToken)
        getters.getPriceReqCancelToken.cancel('Operation canceled because new started.');
      }
      const CancelToken = axios.CancelToken;
      commit('updatePriceReqCancelToken',CancelToken.source());
      try {
        const res = await axios.post('/api/documents/cartPrice',payload,{
          cancelToken: getters.getPriceReqCancelToken.token,
        })
        commit('updatePrices',res.data.prices);
        commit('saveState');
      } catch (thrown:any){
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          console.error(thrown);
        }
      }
    },
    async loadState({state,dispatch}){
      // const data = localStorage.getItem('cart-data');
      // if(!data) return;
      // const parsedData = JSON.parse(data);
      // if(parsedData.productsList){
      //   let outdated = false;
      //   state.productsList = parsedData.productsList.map((el:Product)=>{
      //     el.priceDate = new Date(el.priceDate);
      //     const presetedValue = el.amountProxy ?? 1;
      //     const amountProxy = ref<number>(Number(presetedValue));
      //     el.amountProxy = amountProxy;
      //     el.amount = computed({
      //       get() {
      //         return amountProxy.value
      //       },
      //       set(value:number){
      //         if(value<1){
      //           value = 1;
      //         }
      //         amountProxy.value = value;
      //         dispatch('getPriceForBoard');
      //       }
      //     });
      //     const now = new Date();
      //     if(now.getTime() - el.priceDate.getTime() > 600000){ // 10 min in 600 000 ml seconds
      //       outdated = true;
      //     }
      //     return el;
      //   });
      //   if(outdated){
      //     console.log('Price outdated',parsedData.productsList[0]);
      //     dispatch('getPriceForBoard');
      //     console.log('loaded',parsedData)
      //   }
      //
      // }

    },
  }
}
