import { Module } from "vuex";
import { ProjectState } from "@/store/projects/types";
import { RootState } from "../../types";
import axios from "axios";

export const Assemblies:Module<ProjectState, RootState> = {
    actions: {
        async renameAssemblies({ dispatch, rootState },{
          assembliesToUpdate, projectId
        }){
          console.log(assembliesToUpdate);
          const waitingArr = [];
          for (let i = 0; i < assembliesToUpdate.length; i++) {
            const formData = new FormData();
            formData.append('title', assembliesToUpdate[i].newTitle);
            waitingArr.push(axios.post(`${rootState.apiUri}/api/projects/${projectId}/assemblies/${assembliesToUpdate[i].id}/update`, formData));
          }
          await Promise.all(waitingArr);
          dispatch('loadProjects');
        },
        attachProductsToAssembly({ dispatch, rootState }, {
          products,
          projectId,
          assemblyId
        }){
          const formData = new FormData();
          for (let i = 0; i < products.length; i++) {
            formData.append('products[]',products[i]);
          }
          axios.post(`${rootState.apiUri}/api/projects/${projectId}/assemblies/${assemblyId}/attachProducts`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{
            if(res.data.error){
              console.error('Products not added');
            } else {
              dispatch('loadProjects');
            }
          });
        },
        detachProductsFromAssembly({ dispatch, rootState }, {
          products,
          projectId,
          assemblyId
        }){
          const formData = new FormData();
          for (let i = 0; i < products.length; i++) {
            formData.append('products[]',products[i]);
          }
          axios.post(`${rootState.apiUri}/api/projects/${projectId}/assemblies/${assemblyId}/detachProduct`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{
            if(res.data.error){
              console.error('Products not detached');
            } else {
              dispatch('loadProjects');
            }
          });
        },
        async addAssembly({ dispatch, rootState }, {
          title,
          projectId,
          products
        }){
          const {data} = await axios.post(`${rootState.apiUri}/api/projects/${projectId}/assemblies/add`, {
            title,
            products
          });

          if(!data.error){
            dispatch('loadProjects');
          }
          return data;
        },
        async updateAssembly({ dispatch, rootState }, {
          projectId,
          assemblyId,
          title,
          products
        }){
          const {data} = await axios.post(`${rootState.apiUri}/api/projects/${projectId}/assemblies/${assemblyId}/update`, {
            title,
            products
          });

          if(!data.error){
            dispatch('loadProjects');
          }
          return data;
        },
        hideAssembly({ dispatch, rootState }, { projectId, assemblyId }){
          const formData = new FormData();
          axios.post(`${rootState.apiUri}/api/projects/${projectId}/assemblies/${assemblyId}/hide`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{
            if(res.data.error){
              console.error('Assembly was not hidden');
            } else {
              dispatch('loadProjects');
            }
          });
        },

    }
}
