<template>
  <router-view/>
</template>

<script>
import store from "../store";
import axios from 'axios';
import {mapActions} from 'vuex'
import router from "@/router";
import initGlobal from "@/app/setup/InitGlobal";
import {setupAxiosInterceptorsRequest, setupAxiosInterceptorsResponse} from "@/app/setup/InitAxiosInterceptors";
import {handleError} from "@/utils/HandleError";
import {setupToastWrapper} from "@/utils/SetupToastWrapper";

setupAxiosInterceptorsRequest();
initGlobal();

export default {
  name: "App",
  methods: {
    ...mapActions([
      'checkIfTokenStillValid',
      'fetchAccessToken',
      // 'loadProjectViewSettings',
    ]),
    ...mapActions('Cart',[
      'loadState',
    ]),
    ...mapActions('PreOrderCart',[
      'loadPreOrderState'
    ])
  },
  mounted(){
    // const router = this.$router;
    const toast = this.$vaToast;
    setupToastWrapper(toast);
    setupAxiosInterceptorsResponse(toast);
    this.fetchAccessToken();
    if(this.$store.getters.getToken) {
      this.checkIfTokenStillValid(this.$store.getters.getToken).then(_=>{
        // this.loadPreOrderState();
      })
    }

    // this.loadProjectViewSettings();
    // this.loadState(); // CART state
  },
  errorCaptured(error, vm, info) {
    if(!this.$store.getters.getToken) return;
    handleError(error,vm,info);
  }
}
</script>

<style>
@import "@/app/style/main.css";
</style>
