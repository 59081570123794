import {PartCategoryTab} from "@/components/ExperimParts/ResistanceTabFilters/ResistanceTable";

const connectorsTable:PartCategoryTab = {
  columns: [
    { key: "manufacturer", label: "Manufacturer", sortable: false, width: 200  },
    { key: "part_number", label: "Part Number", sortable: false,thAlign: "start", width: 200  },
    { key: "package", label: "Package", sortable: false, width: 200  },
    { key: "qty", label: "Qty on Hand", sortable: false },
    { key: "locs", label: "Locations", sortable: false },
    { key: "price", label: "Price", sortable: false },
  ],
  packages_ids: [19,28,69,56,13,55,34,1,30,10,26,18,45,58,15,14,49,8,53,77,23,17,68,60,48,83,29,72]
}

export {connectorsTable}
