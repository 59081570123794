import { Module } from "vuex";
import { ProjectState } from "@/store/projects/types";
import { RootState } from "../types";
import axios from "axios";

export const Projects:Module<ProjectState, RootState> = {
    state: {
        projectViewType: "list",
        companies:[],
        selectedCompany:null,
        projects: [
        ],
        isLoadingProjects:false,
        fixedCompany:null
    },
    mutations: {
      startLoadingProjects(state){
        state.isLoadingProjects = true;
      },
      stopLoadingProjects(state){
        state.isLoadingProjects = false;
      },
      updateProjects(state, data){
        state.projects = data;
      },
      updateCompanies(state,companies){
        state.companies = companies
      }
    },
    actions: {
      changeFixedCompany({state, getters, dispatch},id){
        state.fixedCompany = id;
        dispatch('LocalStorage/changeFixedCompany',id);
      },
      loadFixedCompanySettings({ dispatch, getters }){
        dispatch('LocalStorage/loadFixedCompanySettings');
      },
      async loadProjects({ commit, rootState, getters },companyId){
        commit('startLoadingProjects');
        if(getters.getFixedCompany && !companyId) {
          companyId = getters.getFixedCompany
        }
        try {
          const route = rootState.apiUri+`/api/projects/valid`+(companyId?`?companyId=${companyId}`:'')
          const res = await axios.get(route ,{})
          // console.log(res);
          if(res.data.error){
            console.error(res.data.error)
          } else {
            if(res.data.companies){
              commit('updateCompanies',res.data.companies);
            } else {
              commit('updateCompanies',[]);
            }
            commit('updateProjects',res.data.projects);
          }
        } catch (e) {
          console.error(e);
        } finally {
          commit('stopLoadingProjects');
        }
      },
      attachProductsToProjects({ dispatch, rootState },{
        products,
        projectId
      }){
        const formData = new FormData();
        for (let i = 0; i < products.length; i++) {
          formData.append('products[]',products[i]);
        }
        axios.post(`${rootState.apiUri}/api/projects/${projectId}/attachProducts`, formData, ).then(res=>{
          if(res.data.error){
            console.error('Products not added');
          } else {
            dispatch('loadProjects');
          }
        });
      },
      async addProject({ dispatch, rootState },{
        title,
        description,
        markedColor,
        cover
      }){
        const formData = new FormData();
        formData.append('title',title);
        formData.append('description',description);
        formData.append('markedColor',markedColor);

        if(this.getters.getFixedCompany){
          formData.append('comp',this.getters.getFixedCompany);
        }
        if(cover) formData.append('cover',cover);
        const res = await axios.post(`${rootState.apiUri}/api/projects/add`, formData, )
        if(res.data.error){
          console.error('Project not added');
        } else {
          dispatch('loadProjects');
        }
        return res.data?.data?.id ?? null;
      },
      updateProject({ dispatch, rootState },{
        title,
        description,
        markedColor,
        cover,
        id
      }){
        const formData = new FormData();
        formData.append('title',title);
        formData.append('description',description);
        formData.append('markedColor',markedColor);
        if(cover) formData.append('cover',cover);
        axios.post(`${rootState.apiUri}/api/projects/${id}/update`, formData, ).then(res=>{
          if(res.data.error){
            console.error('Project not updated');
          } else {
            dispatch('loadProjects');
          }
        });
      },
      updateProjectVisibility({ dispatch, rootState },{
          id,
          visible
      }){
          const formData = new FormData();
          formData.append('visible', visible);
          axios.post(`${rootState.apiUri}/api/projects/${id}/updateVisibility`, formData, ).then(res=>{
              if(res.data.error){
                  console.error('Project visibility was not updated');
              } else {
                  dispatch('loadProjects');
              }
          });
      },
      updateProductProject({ dispatch, rootState },{
        product_id,
        project_id
      }){
        const formData = new FormData();
        formData.append('product_id', product_id);
        formData.append('project_id', project_id);
        axios.post(`${rootState.apiUri}/api/products/${product_id}/updateProductProject`, formData, ).then(res=>{
          if(res.data.error){
            console.error('Product project updated');
          } else {
            dispatch('loadProjects');
          }
        });
      }
    },
    getters: {
      getFixedCompany(state,getters){
        return state?.fixedCompany ?? getters['LocalStorage/getFixedCompany'];
      },
      getCompanies(state){
        return state.companies;
      },
      isLoadingProjects(state){
        return state.isLoadingProjects;
      },
      getProjectsForList(state){
          return state.projects;
      },
      getColors(){
        return ['#4ae387', '#e34a4a', '#4ab2e3', '#db76df', '#f7cc36', '#f3f3f3', '#000'];
      }
    }
}
