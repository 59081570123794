import { Module } from "vuex";
import { RootState } from "../types";
import axios from "axios";
import {PartsState} from "@/store/parts/types";
import {useToast} from "vuestic-ui";
import {useToastWrapper} from "@/utils/SetupToastWrapper";

export const Parts:Module<PartsState, RootState> = {
  state: {
    parts:[],
    brands:[],
    part: {
      id:1,
      name:'',
      part_number:'',
      image:null,
      brand: {
        id:1,
        name:''
      },
      category:{
        id:1,
        name:'',
        shorts:[''],
        aliases:['']
      },
      manufacturer:{
        id:1,
        name:''
      },
      added_by:{
        id:1,
        name:'',
        lastname: '',
        email: '',
      },
      specifications:[]
    },
    partCategories:[],
    allSpecs:[],
    specCategories:[],
    units:[],
    partAmount:0,
  },
  mutations: {
    clearPartData(state) {
      state.part = {
        id:1,
        name:'',
        part_number:'',
        image:null,
        brand: {
          id:1,
          name:''
        },
        category:{
          id:1,
          name:'',
          shorts:[''],
          aliases:['']
        },
        manufacturer:{
          id:1,
          name:''
        },
        added_by:{
          id:1,
          name:'',
          lastname: '',
          email: '',
        },
        specifications:[]
      }
    },
    updateParts(state,data){
      state.parts = data;
    },
    updateBrands(state,data){
      state.brands = data;
    },
    updatePart(state,part){
      state.part = part;
    },
    updatePartCategories(state,cats){
      state.partCategories = cats;
    },
    updateSpecs(state,specs){
      state.allSpecs = specs;
    },
    updateSpecCategories(state,cats){
      state.specCategories = cats;
    },
    updateUnits(state,units) {
      state.units = units;
    },
    updatePartAmount(state,umount) {
      state.partAmount = umount;
    }
  },
  getters: {
    partAmount(state) {
      return state.partAmount;
    },
    specUnits(state){
      return state.units;
    },
    specCategories(state) {
      return state.specCategories;
    },
    allParts(state){
      return state.parts;
    },
    allBrands(state){
      return state.brands;
    },
    getActualPart(state) {
      return state.part;
    },
    PartCategories(state) {
      return state.partCategories;
    },
    allSpecs(state){
      return state.allSpecs;
    }
  },
  actions: {
    async getPart({commit,rootState},{
      partId
    }){
      const response = await axios.get(`${rootState.apiUri}/api/parts/`+partId);
      if(response.data.error) useToast().init({message: response.data.message, color: 'danger'})
      await commit('updatePart',response.data);
    },
    async getSpecs({commit,rootState,dispatch}){
      const response = await axios.get(`${rootState.apiUri}/api/spec`);
      if(response.data.error) useToast().init({message: response.data.message, color: 'danger'})
      commit('updateSpecs',response.data);
      dispatch('getBrandsList');
      dispatch('getCategoriesList');
    },
    async getPartsList({ commit, rootState, dispatch },data) {
      if(data) {
        commit('updateParts',[]);
        const response = await axios.post(`${rootState.apiUri}/api/parts/filter`,data);
        if(response.data.error) useToast().init({message: response.data.message, color: 'danger'})
        commit('updateParts',response.data);
        dispatch('getPartsQty',response.data.data.map((item:any) => item.id));
        commit('updatePartAmount',response.data.totalAmount);
      } else {
        const response = await axios.get(`${rootState.apiUri}/api/parts`,);
        if(response.data.error) useToast().init({message: response.data.message, color: 'danger'})
        commit('updateParts',response.data);
      }
    },
    async getPartsQty({ commit, rootState, dispatch,state },data) {
      const res = await axios.post(`${rootState.apiUri}/api/parts/qty`,{
        ids: data
      });
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // console.log(state.parts.columns,'hello',state.parts)
      // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // // @ts-ignore
      // if(state.parts.columns.find(el=>el.id == -4) === undefined){
      //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //   // @ts-ignore
      //   state.parts.columns.columns.push({
      //     id:-4,
      //     name:'Amount'
      //   });
      // }

      for (let i = 0; i < res.data.syncResponse.length; i++) {
        const el = res.data.syncResponse[i];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const part = state.parts?.data.find((item:any) => item.id == el.id);
        if(part) {
          part.Amount = el.qty;
        }
      }
      //console.log(res.data);
    },
    async getBrandsList({ commit, rootState }) {
      const response = await axios.get(`${rootState.apiUri}/api/parts/brands/all`,);
      if(response.data.error) useToast().init({message: response.data.message, color: 'danger'})
      commit('updateBrands',response.data);
    },

    async getCategoriesList({ commit, rootState }) {
      const response = await axios.get(`${rootState.apiUri}/api/parts/categories/all`,);
      if(response.data.error) useToast().init({message: response.data.message, color: 'danger'})
      commit('updatePartCategories',response.data);
    },

    async getSpecCategoriesList({ commit, rootState }) {
      const response = await axios.get(`${rootState.apiUri}/api/spec/categories`,);
      if(response.data.error) useToast().init({message: response.data.message, color: 'danger'})
      commit('updateSpecCategories',response.data);
    },

    async getUnitsList({ commit, rootState }) {
      const response = await axios.get(`${rootState.apiUri}/api/spec/units`,);
      if(response.data.error) useToast().init({message: response.data.message, color: 'danger'})
      commit('updateUnits',response.data);
    },

    // async getPartsColumns({ dispatch, rootState }){
    //   console.log('download columns')
    // },


    async downloadALlForPartPage({ dispatch, rootState },partId:number){
      dispatch('getCategoriesList');
      dispatch('getBrandsList');
      dispatch('getSpecs');
      await dispatch('getPart',partId);
    },

    async addEditSpecCategory({ dispatch, rootState },data){
      let path = `${rootState.apiUri}/api/spec/categories/add`;
      if(data.id) {
        path = `${rootState.apiUri}/api/spec/categories/${data.id}`;
      }
      await axios.post(path, {
        name: data.name
      });
      dispatch('getSpecCategoriesList');
      dispatch('downloadALlForPartPage',{partId:data.partId});
    },

    async addEditUnits({ dispatch, rootState },data){
      let path = `${rootState.apiUri}/api/spec/units/add`;
      if(data.id) {
        path = `${rootState.apiUri}/api/spec/units/${data.id}`;
      }
      await axios.post(path, {
        name: data.name,
        short: data.short,
      });
      dispatch('getUnitsList');
      dispatch('downloadALlForPartPage',{partId:data.partId});
    },

    async addEditBrand({dispatch, rootState},data){
      let path = `${rootState.apiUri}/api/manufacturers/add`;
      if(data.id) {
        path = `${rootState.apiUri}/api/manufacturers/${data.id}`;
      }
      await axios.post(path, data);
      dispatch('getBrandsList');
    },
    async deleteBrand({dispatch, rootState},data) {
      const path = `${rootState.apiUri}/api/manufacturers/${data.id}`;
      await axios.delete(path);
      dispatch('getBrandsList');
    },
    async confirmBrand({dispatch, rootState},data){
      if(data.id) {
        const path = `${rootState.apiUri}/api/manufacturers/${data.id}/confirm`;
        await axios.post(path, data);
        dispatch('getBrandsList');
      }
    },

    async addPart({dispatch, rootState},data) {
      const path = `${rootState.apiUri}/api/parts/add`;
      const response = await axios.post(path, data);
      return response.data.data.id;
    },

    async editPart({dispatch, rootState},data) {
      const path = `${rootState.apiUri}/api/parts/${data.id}`;

      const formData = new FormData();
      for (let i = 0; i < Object.keys(data).length; i++) {
        const key = Object.keys(data)[i];
        formData.append(key,data[key]);
      }
      await axios.post(path, formData);
      dispatch('downloadALlForPartPage',{
        partId:data.id
      })
    },

    // async uploadPartImage({dispatch, rootState},data) {
    //   const path = `${rootState.apiUri}/api/parts/${data.id}`;
    //   await axios.post(path, data);
    //   dispatch('downloadALlForPartPage',{
    //     partId:data.id
    //   })
    // },

    async deletePart({dispatch, rootState},data) {
      const path = `${rootState.apiUri}/api/parts/${data.id}`;
      await axios.delete(path);
      // dispatch('getPartsList');
    },

    async deleteSpecPartConnection({dispatch, rootState},data){
      const path = `${rootState.apiUri}/api/parts/removeSpec/${data.id}`;
      await axios.delete(path);
      dispatch('downloadALlForPartPage',{
        partId:data.partId
      });
    },

    async addEditPartCategory({dispatch, rootState},data){
      let path = `${rootState.apiUri}/api/parts/categories/add`;
      if(data.id) {
        path = `${rootState.apiUri}/api/parts/categories/${data.id}`;
      }
      await axios.post(path, data);
      dispatch('getCategoriesList');
    },

    async addEditSpec({dispatch, rootState},data){
      let path = `${rootState.apiUri}/api/spec`;
      if(data.id) {
        path = `${rootState.apiUri}/api/spec/${data.id}`;
      }
      await axios.post(path, {
        name: data.name,
        category_id: data.category_id,
        unit_id: data.unit_id,
        data_type:"text",
        required:false,
      });
      dispatch('getSpecs');
      dispatch('downloadALlForPartPage',{partId:data.partId});
    },

    async addNewSpecificationToPart({ commit, rootState, dispatch },{
      data,
    }) {
      if(data.newValue){
        //add new Value;
        const response = await axios.post(`${rootState.apiUri}/api/spec/${data.spec_id}/options`, {
          specification_id:data.spec_id,
          value:data.newValue.value,
          order:data.newValue.order
        });

        data.opt_id = response.data.data.id;
      }
      const response = await axios.post(`${rootState.apiUri}/api/parts/${data.partId}/addSpec`, {
        specification_id:data.spec_id,
        selected_option:data.opt_id,
        source:'site'
      });
      if(response.data.error) useToast().init({message: response.data.message, color: 'danger'});
      dispatch('downloadALlForPartPage',{
        partId:data.partId
      })
    },

    async saveSpecificationToPart({ commit, rootState, dispatch },{
      data,
    }) {
      if(data.newValue){
        //add new Value;
        const response = await axios.post(`${rootState.apiUri}/api/spec/${data.spec_id}/options`, {
          specification_id:data.spec_id,
          value:data.newValue.value,
          order:data.newValue.order
        });

        data.opt_id = response.data.data.id;
      }
      const response = await axios.post(`${rootState.apiUri}/api/parts/updateSpec/${data.connection_id}`, {
        specification_id:data.spec_id,
        selected_option:data.opt_id,
        source:'site'
      });
      if(response.data.error) {
        useToastWrapper().init({
          message: response.data.message,
          color: 'danger',
        })
      }
        // useToast().init({message: response.data.message, color: 'danger'});
      dispatch('downloadALlForPartPage',{
        partId:data.partId
      })
    },

  },
}
