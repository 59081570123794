import {PartCategoryTab} from "@/components/ExperimParts/ResistanceTabFilters/ResistanceTable";

const fusesTable:PartCategoryTab = {
  columns: [
    { key: "manufacturer", label: "Manufacturer", sortable: false, width: 200  },
    { key: "part_number", label: "Part Number", sortable: false,thAlign: "start", width: 200  },
    { key: "fuse_voltage_ac", label: "Voltage Rating - AC", sortable: false, width: 120  },
    { key: "fuse_voltage_dc", label: "Voltage Rating - DC", sortable: false, width: 120  },
    { key: "fuse_current", label: "Current Rating", sortable: false, width: 120  },
    { key: "package", label: "Package", sortable: false, width: 200  },
    { key: "qty", label: "Qty on Hand", sortable: false },
    { key: "locs", label: "Locations", sortable: false },
    { key: "price", label: "Price", sortable: false },
  ],
  packages_ids: [71]
}

export {fusesTable}
