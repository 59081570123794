const dir = {
  beforeMount: (el:any, binding:any) => {
    const onResizeCallback = binding.value;
    el.resizeEvent = (event:any) => {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      onResizeCallback({ width, height });
    };
    window.addEventListener("resize", el.resizeEvent);
  },
  unmounted: (el:any) => {
    window.removeEventListener("resize", el.resizeEvent);
  },
};

export default dir;
