import {Module} from "vuex";
import {RootState} from "@/store/types";
import {parse} from "@typescript-eslint/parser";

interface BoardTabState { // State
  activeSubTab: number; // Currently active sub-tab
  panelization_y: number; // Y-coordinate for panelization
  via_process: string; // Process for creating vias
  edge_rail: string; // Edge rail type
  fake_edge_rail: any[]; // Array of fake edge rail types
  edge_rail_width: number; // Width of edge rail
  rout_width_vertical: number|null; // Vertical routing width
  rout_width_horizontal: number|null; // Horizontal routing width
  panel_cutting: any; // Panel cutting type
  panelization_x: number; // X-coordinate for panelization
  x_out_panel: boolean; // Flag for x-out panel
  numberLayers: number|string; // Number of layers
  boardLength: number; // Length of the board
  boardWidth: number; // Width of the board
  useOutlineLayerMask: boolean; // Indicator if outline layer mask is used
  maskColor: string; // Color of the outline layer mask
  silkColor: string; // Color of the silk
  outerCooper: number; // Thickness of outer copper
  innerCooper: number; // Thickness of inner copper
  boardFiles: any[];
  selectedFile: number; // Index of the selected file
  selectedBoardFileId: number; // ID of the selected board file
  selectedFileId: number; // ID of the selected file
  boardThickness: number|string; // Thickness of the board
  finishing: string; // Type of finishing
  unitsIn: string; // Unit system
  boardMaterial: string; // Material of the board
  customerWillSupplyBareBoard: boolean; // Indicator if customer will supply bare board
  panelization: boolean; // Indicator if panelization is enabled
  min_hole_size: number; // Minimum hole size
}

export const BoardTab:Module<BoardTabState, RootState> = {
  namespaced: true,
  state: {
    activeSubTab: 1, // Currently active sub-tab
    panelization_y: 0, // Y-coordinate for panelization
    via_process: '', // Process for creating vias
    edge_rail: '', // Edge rail type
    fake_edge_rail: [], // Array of fake edge rail types
    edge_rail_width: 0.15, // Width of edge rail
    rout_width_horizontal:null,
    rout_width_vertical:null,
    panel_cutting: null, // Panel cutting type
    panelization_x: 0, // X-coordinate for panelization
    x_out_panel: false, // Flag for x-out panel
    selectedFile: -1, // Index of the selected file
    selectedBoardFileId: -1, // ID of the selected board file
    selectedFileId: -1, // ID of the selected file
    boardThickness: 0, // Thickness of the board
    finishing: 'HASL Lead Free', // Type of finishing
    unitsIn: 'imperial', // Unit system
    boardMaterial: 'FR4', // Material of the board
    customerWillSupplyBareBoard: false, // Indicator if customer will supply bare board
    panelization: true, // Indicator if panelization is enabled
    numberLayers: '0', // Number of layers in the board
    boardWidth: 0, // Width of the board
    boardLength: 0, // Length of the board
    useOutlineLayerMask: true, // Indicator for using outline layer mask
    maskColor: '', // Color of the mask
    silkColor: '', // Color of the silk
    outerCooper: 1, // Thickness of outer copper layer
    innerCooper: 1, // Thickness of inner copper layer
    boardFiles: [], // Array of board files
    min_hole_size: 0.3, // Minimum hole size
  },
  getters: {
    getCooperFilesAmount(state) {
      return state.boardFiles.filter((el:any) => (el.file_type === 'bottomCopper' || el.file_type === 'topCopper')).length
    },
    isSomeChangedInSettings(state,getters,rootState,rootGetters) {
      const settings = rootGetters.productSettings;
      // for (let i = 0; i < Object.keys(settings).length; i++) {
      //   const key = Object.keys(settings)[i];
      //   if(settings[key] !== state[key as keyof BoardTabState]) {
      //     console.log(settings[key],state[key as keyof BoardTabState],key)
      //   }
      // }
      if(state.boardThickness !== settings?.boardThickness) return true;
      if(state.finishing !== settings.finishing) return true;
      if(state.unitsIn !== settings.unitsIn) return true;
      if(state.boardMaterial !== settings.boardMaterial) return true;
      if(state.customerWillSupplyBareBoard !== settings.customerWillSupplyBareBoard) return true;

      if(state.panelization !== settings.panelization) return true;
      if(state.panelization_x !== settings.panelization_x) return true;
      if(state.panelization_y !== settings.panelization_y) return true;
      if(state.via_process !== settings.via_process) return true;
      if(state.edge_rail !== settings.edge_rail) return true;

      if(state.edge_rail_width !== settings.edge_rail_width) return true;
      if(state.rout_width_horizontal !== settings.rout_width_horizontal) return true;
      if(state.rout_width_vertical !== settings.rout_width_vertical) return true;
      if(state.panel_cutting !== settings.panel_cutting) return true;
      if(state.x_out_panel !== settings.x_out_panel) return true;
      if(state.numberLayers !== settings.numberLayers) return true;
      if(state.boardWidth !== settings.boardWidth) return true;
      if(state.boardLength !== settings.boardLength) return true;
      if(state.useOutlineLayerMask !== settings.useOutlineLayerMask) return true;

      if(state.maskColor !== settings.maskColor) return true;
      if(state.silkColor !== settings.silkColor) return true;
      if(state.outerCooper !== settings.outerCooper) return true;
      if(state.innerCooper !== settings.innerCooper) return true;
      if(state.min_hole_size !== settings.min_hole_size) return true;

      return false;
    },
    isSomethingChanged(state, getters) {
      return getters.isSomeChangedInSettings || getters.isSomeChangedInBoardFiles;
    },
    isSomeChangedInBoardFiles(state,getters,rootState,rootGetters) {
      const productBoardFiles = rootGetters.productBoardFiles;
      for (let i = 0; i < productBoardFiles.length; i++) {
        const fileState = state.boardFiles[i];
        const file = productBoardFiles[i];
        if(
          file.color !== fileState.color ||
          file.file_type !== fileState.file_type ||
          file.isVisible !== fileState.isVisible ||
          file.layer_number !== fileState.layer_number ||
          file.id !== fileState.id ||
          file.product_file_id !== fileState.product_file_id
        ){
          return true;
        }
      }
      return false;
    },
    boardFiles(state) {
      return state.boardFiles;
    },
    errorsBoardTab(state) {
      const errors = [];

      if(state.boardFiles.length === 0) {
        errors.push({
          type: 'files',
          message: 'There are no files attached to the board'
        })
      }

      if(state.boardFiles.filter(file => file.file_type === 'initialType').length !== 0) {
        errors.push({
          type: 'initialType',
          message: 'Files types should be selected'
        });
      }

      if(state.boardFiles.filter(file => file.file_type === 'platedDrill' || file.file_type === 'nonPlatedDrill').length === 0){
        errors.push({
          type: 'drills',
          message: 'Drills should be selected'
        });
      }

      const internalLayersFiles = state.boardFiles.filter(file => file.file_type === 'internalLayer');

      if(internalLayersFiles.length != 0){
        if(!state.innerCooper) {
          errors.push({
            type: 'innerCooper',
            message: 'Inner copper weight should be selected'
          });
        }
        const withOutLayerOrder = internalLayersFiles.filter(file => file.layer_number === null);
        if(withOutLayerOrder.length != 0){
          errors.push({
            type: 'layerOrder',
            message: 'Layers order should be selected'
          });
        }
      }

      if (!state.outerCooper) {
        errors.push({
          type: 'outerCooper',
          message: 'Outer copper weight should be selected'
        });
      }

      return errors;
    },
    isValidatedBoardFilesTab(state,getters,rootState,rootGetters) {
      return getters.errorsBoardTab.length === 0;
    },
    errorsBoardSettingsTab(state, getters, rootState, rootGetters) {
      const errors = [];
      const edgeRailsOptions = rootGetters.boardSettingsOptions?.edgeRailsOptions;

      if(state.fake_edge_rail.length > 0){
        for (let i = 0; i < state.fake_edge_rail.length; i++) {
          if(edgeRailsOptions?.find((el:any)=>el === state.fake_edge_rail[i]) === undefined){
            errors.push({
              type: 'edge_rail',
              message: 'Edge rails should be selected'
            });
            break;
          }
        }
      }

      if(state.boardLength <= 0 || state.boardWidth <= 0){
        errors.push({
          type: 'boardSizes',
          message: 'Board sizes should be selected'
        });
      }

      if(state.panelization){
        const isItImperial = state.unitsIn === 'imperial';

        if(state.panelization_x <= 0 || state.panelization_y <= 0){
          errors.push({
            type: 'panelizationSizes',
            message: 'Panelization sizes should be selected'
          });
        }

        const edge_rail_width_limit = isItImperial ? 0.15 : 4;
        if(state.edge_rail_width < edge_rail_width_limit && !(state.edge_rail==='None' || (state.edge_rail === null || state.edge_rail === ''))){
          errors.push({
            type: 'edge_rail_width',
            message: `Edge rail width should be greater than ${edge_rail_width_limit} ${isItImperial ? 'in' : 'mm'}`
          });
        }

        const selectedPanelCutting = rootGetters.boardSettingsOptions?.panel_cutting_options?.find((el:any)=>el === state.panel_cutting);

        if(selectedPanelCutting === undefined || state.panel_cutting === null) {
          errors.push({
            type: 'panel_cutting',
            message: 'Panel cutting should be selected'
          });
        }
        if(selectedPanelCutting?.toLowerCase() === 'v-scoring') {
          const v_scor_limit = isItImperial ? 3 : 75;
          let panel_width = 0;
          let panel_height = 0;

          if(state.panelization_x > 0 && state.panelization_y > 0) {
            panel_width = state.boardWidth*state.panelization_x;
            panel_height = state.boardLength*state.panelization_y;

            if(state.edge_rail_width > 0) {
              panel_width += state.edge_rail_width*2;
              panel_height += state.edge_rail_width*2;
            }

          }

          if(panel_height < v_scor_limit || panel_width < v_scor_limit) {
            errors.push({
              type: 'boardSizes',
              message: `Panel sizes should be greater than ${v_scor_limit}${isItImperial ? 'in' : 'mm'} for V-Scoring`
            });
          }
        }

        const limits = {
          max_mm:10,
          min_mm:2,
          max_in:0.393701,
          min_in:0.0787402
        }

        const min_value = (state.unitsIn === 'imperial')?limits.min_in:limits.min_mm;
        const max_value = (state.unitsIn === 'imperial')?limits.max_in:limits.max_mm;

        const checkMinMax = (value:any,min:number,max:number)=>{
          const p_value = parseFloat(value);
          if (p_value <= max && p_value >= min) {
            return true;
          } else {
            return false;
          }
        }

        const checkTabRoutVScor = ()=>{
          if(state.panel_cutting === 'V-Scoring+Tab-routing') {
            if(state.rout_width_horizontal != 0
              && checkMinMax(state.rout_width_horizontal,min_value,max_value)
              && state.rout_width_vertical == 0
            ) {
              return true
            }

            if(state.rout_width_vertical != 0
              && checkMinMax(state.rout_width_vertical,min_value,max_value)
              && state.rout_width_horizontal == 0) {
              return true;
            }
            return false;
          }
          return false
        }
        if(selectedPanelCutting === 'Tab-routing') {
          if(!checkMinMax(state.rout_width_horizontal,min_value,max_value) || !checkMinMax(state.rout_width_vertical,min_value,max_value)) {
            errors.push({
              type: 'routWidth',
              message: 'Rout width should be selected'
            });
          }
        }

        if(selectedPanelCutting === 'V-Scoring+Tab-routing') {
          if(!checkTabRoutVScor()) {
            errors.push({
              type: 'routWidth',
              message: 'Rout width should be selected'
            });
          }
        }
      }

      if(rootGetters.boardSettingsOptions?.boardMaterialsOptions?.find((el:any)=>el === state.boardMaterial) === undefined) {
        errors.push({
          type: 'boardMaterial',
          message: 'Board material should be selected'
        });
      }

      if(rootGetters.boardSettingsOptions?.layersNumbersOptions?.find((el:any)=>el === state.numberLayers) === undefined) {
        errors.push({
          type: 'numberLayers',
          message: 'Number of layers should be selected'
        });
      }

      if(rootGetters.boardSettingsOptions?.boardThicknessOptions?.find((el:any)=>el.mm == state.boardThickness) === undefined) {
        errors.push({
          type: 'boardThickness',
          message: 'Board thickness should be selected'
        });
      }

      if(rootGetters.boardSettingsOptions?.finishingOptions?.find((el:any)=>el === state.finishing) === undefined) {
        errors.push({
          type: 'finishing',
          message: 'Finishing should be selected'
        });
      }

      if(rootGetters.boardSettingsOptions?.unitsOptions?.find((el:any)=>el === state.unitsIn) === undefined) {
        errors.push({
          type: 'units',
          message: 'Units should be selected'
        });
      }

      if(rootGetters.boardSettingsOptions?.options_via_process?.find((el:any)=>el === state.via_process) === undefined) {
        errors.push({
          type: 'via_process',
          message: 'Via process should be selected'
        });
      }

      return errors;
    },
    isValidatedBoardSettingsTab(state, getters, rootState, rootGetters) {
      return getters.errorsBoardSettingsTab.length === 0;
    }
  },
  mutations: {
    updateFiles(state, productBoardFiles) {
      state.boardFiles = [];
      for (let i = 0; i < productBoardFiles.length; i++) {
        const boardFile = productBoardFiles[i];
        state.boardFiles.push({
          color:boardFile.color,
          file_type:boardFile.file_type,
          isVisible:boardFile.isVisible,
          layer_number:boardFile.layer_number,
          // cooper_weight:boardFile.cooper_weight,
          id:boardFile.id,
          product_file_id:boardFile.product_file_id,
          guess:boardFile.guess,
          file:{
            name:boardFile.file.name,
            id:boardFile.product_file_id,
            link:boardFile.file.link,
            downloadLink:boardFile.file.downloadLink
          }
        })
      }
    },
    updateSettings(state, settings) {
      state.boardThickness = settings.boardThickness;//parseFloat().toFixed(3);
      state.finishing = settings.finishing;
      state.unitsIn = settings.unitsIn;
      state.boardMaterial = settings.boardMaterial;
      state.customerWillSupplyBareBoard = settings.customerWillSupplyBareBoard;
      state.panelization = settings.panelization;
      state.panelization_x = settings.panelization_x;
      state.panelization_y = settings.panelization_y;
      state.via_process = settings.via_process;
      state.edge_rail = settings.edge_rail;
      state.edge_rail_width = settings.edge_rail_width;
      state.rout_width_horizontal = settings.rout_width_horizontal;
      state.rout_width_vertical = settings.rout_width_vertical;
      state.panel_cutting = settings.panel_cutting;
      state.x_out_panel = settings.x_out_panel;
      state.numberLayers = settings.numberLayers;
      state.boardWidth = settings.boardWidth;
      state.boardLength = settings.boardLength;
      state.useOutlineLayerMask = settings.useOutlineLayerMask;
      state.maskColor = settings.maskColor;
      state.silkColor = settings.silkColor;
      state.outerCooper = settings.outerCooper;
      state.innerCooper = settings.innerCooper;
      state.min_hole_size = settings.min_hole_size

      if(state.edge_rail === 'Round') {
        state.fake_edge_rail = ['Top and Bottom Side','Left and Right Side'];
      }
      if(state.edge_rail === 'None'){
        state.fake_edge_rail = [];
      }
      if(state.edge_rail === 'Top and Bottom Side'){
        state.fake_edge_rail = ['Top and Bottom Side'];
      }
      if(state.edge_rail === 'Left and Right Side'){
        state.fake_edge_rail = ['Left and Right Side'];
      }
    }
  },
  actions: {
    updateData({commit, rootGetters}, productBoard){
      const files = productBoard.productBoardFiles.sort((el1:any,el2:any)=>{
        const types = rootGetters['boardFilesTypesOrdered'];
        const first = types.findIndex((el:any)=>{
          return el == el1.file_type
        });
        const second = types.findIndex((el:any)=>{
          return el == el2.file_type
        });
        return first-second
      });
      commit('updateFiles', productBoard.productBoardFiles);
      commit('updateSettings', productBoard.settings);
    },
  },
}
