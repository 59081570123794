import { createApp } from 'vue'
import App from './App.vue'
import router from '../router'
import store from '../store'
import {
  createVuestic
} from 'vuestic-ui'
import 'vuestic-ui/css'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LvButton from "lightvue/button";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LvColorpicker from "lightvue/color-picker";
import clickOutside from "@/utils/ClickOutsideDirective";
import dir from "@/utils/WindowResized";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(createVuestic())
  .use(VueVirtualScroller)
  .component('LvButton', LvButton)
  .component('LvColorpicker',LvColorpicker)
  .directive("click-outside", clickOutside)
  .directive('resize', dir)

export {app}
