import {Module} from "vuex";
import {RootState} from "@/store/types";
import {
  BoardViewerState,
  BufferFile,
  File,
  FormatedFile,
  Package,
  PackagePlacement,
  Placement,
  ProxyBufferFile,
  SelectedPlacementInfo,
  SideStackup,
  Stackup
} from "@/store/products/BoardViewer/types";
import axios from "axios";
import store from "@/store";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {pcbStackup} from "@/assets/js/pcb-stackup.min";
import Decimal from "decimal.js";
import {calculateBoardSizeFromFiles} from "@/components/product/boardViewer/Utils/BoardSizeFromFiles";
import {getFormatedFilesForBoardRender, hexToRgb} from "@/store/products/BoardViewer/FormatBoardFiles";
import {syncBoardFilesBuffer} from "@/store/products/BoardViewer/SyncBoardFilesBuffer";
import {getLayers} from "@/store/products/BoardViewer/GetLayers";

export const BoardInfo:Module<BoardViewerState, RootState> = {
  namespaced: true,
  state: {
    loadingFilesStep: 0,
    layersColors:{},
    layersVisibility:{},
    isMultiBOMEdit:false, // show that we edit all parts placements in the same bom row instead of one part at a time
    board:{
      drawPackages:true,
      labelFontSize:2,
      showLabels:true,
      backgroundColor:'#E7EEF1',
      clickedElementsEnable:false,
      boardSide:'top',
      selectedLayer:null,
      layersOptions:[
      ],
      clickedElementData:{
        id:'',
        x:0,
        y:0,
      },
      selectedPlacement:null,
      clickedPlacement:null,
      editPlacementFlag:false,
      focusInfoForPlacement:{
        next:undefined,
        prev:undefined,
        options:[]
      },
    },
    showingMode: 'side',
    availableMods: [
      'side',
      'layers'
    ],
    stackup: {
      top: {
        attributes: {
          "clip-rule": '',
          "fill-rule":'',
          height:'0',
          id:'',
          'stroke-linecap':'',
          'stroke-linejoin':'',
          'stroke-width': '',
          version:'',
          viewBox:[0,0,0,0],
          width:'0',
          xmlns:'',
          'xmlns:xlink':''
        },
        svg:'',
        defs: [],
        layer: [],
        viewBox: [
          0,
          0,
          0,
          0
        ],
        width:0,
        height:0,
        units: '',
      },
      bottom: {
        attributes: {
          "clip-rule": '',
          "fill-rule":'',
          height:'0',
          id:'',
          'stroke-linecap':'',
          'stroke-linejoin':'',
          'stroke-width': '',
          version:'',
          viewBox:[0,0,0,0],
          width:'0',
          xmlns:'',
          'xmlns:xlink':''
        },
        defs: [],
        layer: [],
        viewBox: [
          0,
          0,
          0,
          0
        ],
        width:0,
        height:0,
        svg:'',
        units: '',
      },
      layers: [],
    },
    DEFAULT_COLOR: {
      fr4: '#666',
      cu: '#ccc',
      cf: '#c93',
      sm: 'rgba(0,101,25,0.75)',
      ss: '#fff',
      sp: '#999',
      out: '#000',
    },
    files: {
      isLoading:false,
      buffer: [],
    },
    isLoading: false,
    placements: {
      defs:[],
      packages:[],
      labels:[],
      noPackages:[],
      dnp:[],
    },
    selectableElements:[],
    isSelectingElementMode:false,
    selectMode:'single',
  },
  getters: {
    /**
     * The files property in the state. This getter is used to access buffer object for board files in the state.
     * @param {BoardViewerState} state The state of the module.
     * @returns {FilesState} The files state.
     */
    bufferedFiles(state){
      return state.files;
    },
    isMultiBOMEdit(state){
      return state.isMultiBOMEdit;
    },
    allPlacementsInBomRowOfSelectedPart(state,getters,rootState,rootGetters){
      const selectedPlacement = rootGetters.placements.find((el:any)=>el.id === state.board.selectedPlacement);
      if(!selectedPlacement) return [];
      const bom = rootGetters.boms.find((el:any)=>el.refDesign.find((el2:any)=>el2.refDesign?.toLowerCase() === selectedPlacement.refDesign?.toLowerCase()));
      if(!bom) return [];
      const refDesignsOfBom = bom.refDesign.map((el:any)=>el.refDesign.toLowerCase());
      return rootGetters.placements.filter((el: any) => refDesignsOfBom.includes(el.refDesign.toLowerCase()));
    },
    placementsList(state){
      return state.placements;
    },
    selectingMode(state){
      return state.selectMode;
    },
    isSelectingElementMode(state){
      return state.isSelectingElementMode;
    },
    selectableRects(state, getters) {
      return state.selectableElements;
    },
    /**
     * The currently selected placement ID on the board.
     * @param {BoardViewerState} state The state of the module.
     * @returns {string | null} The currently selected placement ID on the board, or null if no placement is selected.
     */
    selectedPlacement(state){
      return state.board.selectedPlacement;
    },
    selectedPlacementObj(state,getters,rootState,rootGetters):SelectedPlacementInfo{
      const plId = state.board.clickedPlacement;
      const plObj:SelectedPlacementInfo = {
        refDesign:'',
        placement:null,
        part:null
      }
      if(plId){
        const placement = rootGetters.placements.find((el:any)=>el.id === plId);
        const ref = placement.refDesign;
        plObj.placement = {
          id:placement.id,
          pkgName:placement?.package?.pkgName,
        };
        if(placement.bom_id) {
          const bom = rootGetters.boms.find((el:any)=>el.id === placement.bom_id);
          if(bom.part_info) {
            plObj.part = {
              id:bom.id,
              part_number:bom.part_number,
              part_info:{
                id:bom.part_info.id,
                part_number:bom.part_info.part_number,
                manufacturer:bom.part_info.manufacturer,
                category:bom.part_info.category,
                short_specs:bom.part_info.short_specs
              }
            };
          }
        }
        plObj.refDesign = ref;
      }
      // console.log(plObj);
      return plObj;
    },
    stackup(state){
      let stackup:SideStackup|undefined = undefined;
      const selectedLayer = state.board.layersOptions.find(el=>el.id === state.board.selectedLayer);
      if(selectedLayer){
        if(state.board.selectedLayer === 2 || state.board.selectedLayer === 3){
          if(typeof state.stackup !== 'string') {
            switch (selectedLayer.name.toLowerCase()) {
              case 'top':
                stackup = state.stackup.top;
                break;
              case 'bottom':
                stackup = state.stackup.bottom;
                break;
            }
          }
        } else {
          if(typeof state.stackup !== 'string' && selectedLayer.layer_index !== null) {
            stackup = state.stackup.layers[selectedLayer.layer_index].converter;
          }
        }
      }
      return stackup;
    },
    svg(state,getters){
      const stackup:SideStackup = getters.stackup;
      let addInFlag = false
      const attributes = stackup?.attributes;

      if(!attributes?.width?.includes('in')){
        addInFlag = false;
      }

      const width = addInFlag?attributes?.width+'in':attributes?.width;
      const height = addInFlag?attributes?.height +'in':attributes?.height ;

      const svgParams = {
        "clip-rule": attributes?.["clip-rule"] ?? '',
        "fill-rule": attributes?.["fill-rule"] ?? '',
        height: height ?? 0,
        width:width ?? 0,
        id: attributes?.id ?? '',
        'stroke-linecap': attributes?.['stroke-linecap'] ?? '',
        'stroke-linejoin': attributes?.['stroke-linejoin'] ?? '',
        'stroke-width': attributes?.['stroke-width'] ?? '',
        version: attributes?.version ?? '',
        viewBox:attributes?.viewBox ?? [0,0,0,0],
        xmlns:attributes?.xmlns ?? '',
        'xmlns:xlink':attributes?.['xmlns:xlink'] ?? ''
      }
      return svgParams;
    },
    layerSvg(state,getters){
      const stackup = getters.stackup;
      const layers = [];
      if(stackup) {
        layers.push(...stackup.layer)
      }
      return layers
    },
    selectedLayer(state){
      const selectedLayer = state.board.layersOptions.find(el=>el.id === state.board.selectedLayer);
      if(selectedLayer){
        return selectedLayer.name;
      }
      return 'top';
    },
    toShowBoardLabel(state,getters,rootState,rootGetters){
      if(state.files.isLoading) return 'Rendering'
      const visibleFilesBoardTab = rootGetters['BoardTab/boardFiles'].filter((el:File)=>el.isVisible);
      const visibleFiles = rootGetters.productBoardFiles.filter((el:File)=>el.isVisible);
      const usedFiles = (visibleFilesBoardTab.length >0 )?visibleFilesBoardTab:visibleFiles;

      if(usedFiles.length === 1){
        const fileName = usedFiles[0].file?.name;
        return fileName;
      }
      if(usedFiles.length === 0){
        return `No visible files`;
      }
      const selectedLayer = state.board.layersOptions.find(el=>el.id === state.board.selectedLayer);
      if(selectedLayer){
        return selectedLayer.name;
      }
      return 'No visible files';
    },
    defs(state,getters){
      const stackup = getters.stackup;
      if(stackup){
        return stackup.defs.join('');
      }
      return '';
    },
    translateGValue(state,getters){
      // const side = getters.selectedLayer.toLowerCase();
      // console.log(this.boardInfo.computed.svg.viewBox)
      // this.boardInfo.computed.svg.viewBox[1]*2+this.boardInfo.computed.svg.viewBox[3]
      const vb = getters.svg.viewBox.length == 4? getters.svg.viewBox:getters.svg.viewBox.split(' ').map((el:string)=>parseFloat(el));
      // console.log({
      //   total:vb[1]*2+vb[3],
      //   v1:vb[1]*2,
      //   v2:vb[3],
      //   vb
      // })
      const value = Decimal.round(vb[1]*2+vb[3]).toNumber();///this.boardInfo.state.stackup[side]?.viewBox[1]*2+this.boardInfo.state.stackup[side]?.viewBox[3];
      if(isNaN(value)) return 0;
      return value;
    }
  },
  mutations: {
    changeIsSelectingElementMode(state,newValue){
      state.isSelectingElementMode = newValue;
    },
    changeSelectMode(state, newValue) {
      state.selectMode = newValue;
    },
    clearState(state) {
      state.files.buffer = [];
      state.files.isLoading = false;
      state.loadingFilesStep = 0;
      state.layersColors = {};
      state.board = {
        drawPackages:true,
        labelFontSize:2,
        showLabels:true,
        backgroundColor:'#E7EEF1',
        clickedElementsEnable:false,
        boardSide:'top',
        selectedLayer:null,
        layersOptions:[
        ],
        clickedElementData:{
          id:'',
          x:0,
          y:0,
        },
        clickedPlacement:null,
        selectedPlacement:null,
        editPlacementFlag:false,
        focusInfoForPlacement:{
          next:undefined,
          prev:undefined,
          options:[]
        },
      }
      state.stackup = {
        top: {
          attributes: {
            "clip-rule": '',
            "fill-rule":'',
            height:'0',
            id:'',
            'stroke-linecap':'',
            'stroke-linejoin':'',
            'stroke-width': '',
            version:'',
            viewBox:[0,0,0,0],
            width:'0',
            xmlns:'',
            'xmlns:xlink':''
          },
          svg:'',
          defs: [],
          layer: [],
          viewBox: [
            0,
            0,
            0,
            0
          ],
          width:0,
          height:0,
          units: '',
        },
        bottom: {
          attributes: {
            "clip-rule": '',
            "fill-rule":'',
            height:'0',
            id:'',
            'stroke-linecap':'',
            'stroke-linejoin':'',
            'stroke-width': '',
            version:'',
            viewBox:[0,0,0,0],
            width:'0',
            xmlns:'',
            'xmlns:xlink':''
          },
          defs: [],
          layer: [],
          viewBox: [
            0,
            0,
            0,
            0
          ],
          width:0,
          height:0,
          svg:'',
          units: '',
        },
        layers: [],
      }
      state.isLoading = false;
      state.placements = {
        defs:[],
        packages:[],
        labels:[],
        noPackages:[],
        dnp:[],
      }
      state.selectableElements = [];
      state.isSelectingElementMode = false;
      state.selectMode = 'single'
    },
    addBufferedFile(state,file:BufferFile){
      state.files.buffer.push(file);
    },
  },
  actions: {
    /**
     * Change board displaying mode .
     * Available modes: 'side', 'layers'
     *
     * @param {object} state - The state object .
     * @param {string} newModeName - The new mode name to change to.
     */
    changeDisplayMode({state},newModeName:string): void
    {
      if(!state.availableMods.includes(newModeName)) {
        console.warn('Available modes: '+state.availableMods.join(', '));
        return console.error('Bad new mode');
      }
      state.board.drawPackages = newModeName !== 'layers';
      state.showingMode = newModeName;
    },
    async loadBoardFileContent(_,file:FormatedFile) : Promise<ProxyBufferFile|null>
    {
      console.time('loadBoardFileContent'+file.id)
      const req = await axios.get(file.path);
      if(req.data.error) {
        return null;
      }
      console.timeEnd('loadBoardFileContent'+file.id)
      return {
        id:file.id,
        content:req.data,
      };
    },
    getLayers({state},files:FormatedFile[]){
      state.board.layersOptions = [];
      state.board.selectedLayer = null;
      if(files.find(el=>el.pos === 'top')){
        state.board.layersOptions.push({
          id:2,
          name:'Top',
          layer_index:null
        })
        if(state.board.selectedLayer === null){
          state.board.selectedLayer = 2;
        }
      }

      if(files.find((el:FormatedFile)=>el.pos === "bottom")){

        state.board.layersOptions.push({
          id:3,
          name:'Bottom',
          layer_index:null
        })
        if(state.board.selectedLayer === null){
          state.board.selectedLayer = 3;
        }
      }
    },
    async getFinalSvg({state, dispatch, getters},{
      layers,files
    }){
      const productSettings = store.getters.productSettings;
      state.stackup = '';
      // console.log('final step',layers,files)
      if(layers.length!==0 && files.length !== 0){
        // console.log('init stackup');
        const currentColors = {
          ...state.DEFAULT_COLOR
        }
        if(productSettings.maskColor) {
          const hexCode = hexToRgb(productSettings.maskColor);
          // console.log(hexCode);
          if(hexCode !== 'rgba(246,245,246,0.75)'){
            currentColors.sm = hexCode;
          }
        }

        const finishing_type = productSettings.finishing;

        switch (finishing_type) {
          case ('Bare Copper'): {
            currentColors.cf = '#c93';
            break;
          }
          case ('HASL with Lead'): {
            currentColors.cf = '#C0C0C0';
            break;
          }
          case ('HASL Lead Free'): {
            currentColors.cf = '#C0C0C0';
            break;
          }
          case ('ENIG (Immersion Gold)'): {
            currentColors.cf = '#FFD700';
            break;
          }
          case ('OSP'): {
            currentColors.cf = '#c93';
            break;
          }
          case ('Immersion Tin'): {
            currentColors.cf = '#C0C0C0';
            break;
          }
          case ('Gold Plating'): {
            currentColors.cf = '#FFD700';
            break;
          }
          case ('ENEPIG'): {
            currentColors.cf = '#FFD700';
            break;
          }
          default: {
            currentColors.cf = '#c93';
            break;
          }
        }

        if(productSettings.silkColor) {
          currentColors.ss = productSettings.silkColor;
        }
        console.time('calc pcb')
        state.stackup = await pcbStackup(layers, {color: currentColors,outlineGapFill:1,});
        console.timeEnd('calc pcb')
        console.log(state.stackup);
      } else {
        state.stackup = '';
      }
      state.isLoading = false;
      // console.log(getters.selectableRects)
    },
    async renderBoard({state, dispatch},files:File[]): Promise<void>
    {
      console.time('render board')
      console.log('render board',files);
      const formatedFiles:FormatedFile[] = getFormatedFilesForBoardRender(files)//await dispatch('formatBoardsFiles',files);

      state.files.isLoading = true;
      await syncBoardFilesBuffer(files,formatedFiles);
      state.files.isLoading = false;

      // Get layers to produce board svg
      const layers = getLayers(formatedFiles);

      // Get layers to show in cntrl panel (Top, Bottom, internal layers)
      dispatch('getLayers',formatedFiles); // getLayers(filteredByVisibleFiles);

      await dispatch('getFinalSvg',{layers,files:formatedFiles});

      console.timeEnd('render board');
      calculateBoardSizeFromFiles();
    },
    getCoordsForLabelIfPlacementWithAngle({state},payload) {
      const {x_placement,y_placement,x_label,y_label, placement_angle} = payload;
      const radius = (x_label-x_placement);
      const new_label_x = x_placement + radius*Math.cos(-placement_angle*Math.PI/180);
      const new_label_y = y_placement + radius*Math.sin(-placement_angle*Math.PI/180);
      return {
        x:new_label_x,
        y:new_label_y
      }
    },
    async changePlacement({state, rootGetters, getters,dispatch},payload) {
      const {placementId, x, y, angle} = payload;
      const formatCoord = (el:number)=>parseFloat((el*1000).toFixed(3));
      const side:string = getters.selectedLayer;
      const placement:Placement = rootGetters.placements.find((el:Placement)=>el.id === placementId);
      if(placement) {
        placement.x = x;
        placement.y = y;
        placement.angle = angle;

        if(placement.package) {
          const packagePlacementIndex = state.placements.packages.findIndex((el:PackagePlacement)=>el.plId === placementId);

          if(packagePlacementIndex !== -1) {
            let useY = placement.y;//+100;
            let useX = placement.x;//-100;

            useY = await dispatch('convertIntoCorrectUnits', {
              value: useY,
              reverse:false
            });
            useX = await dispatch('convertIntoCorrectUnits', {
              value: useX,
              reverse:false
            });

            useY = formatCoord((useY));
            useX = formatCoord((useX));

            // product
            if (side.toLowerCase() === 'bottom') {
              useX = (getters.stackup.width * 1000) - useX + (getters.stackup.viewBox[0] * 2);
            }

            const forRotate = placement.angle != 0 ? `rotate(${placement.angle}deg)` : ''; //, ${defX + defWidth/2}, ${defY + defHeight/2}
            // console.error(state.placements.packages[packagePlacementIndex],packagePlacementIndex,state.placements.packages.length);
            state.placements.packages[packagePlacementIndex] = {
              plId: placement.id,
              id: `renderFor${placement.id}`,
              style: `transform: ${forRotate} scaleY(-1); transform-origin: ${useX}px ${useY}px;`,
              y: useY,
              x: useX,
              href: `#defPlace${placement.package.id}`,
            };


            if(state.board.showLabels) {
              const packageObj = state.placements.defs.find((el:any)=>el.i === placement.package.id);
              if(!packageObj) return;
              let label_x = (useX+packageObj.width/2+packageObj.x);
              let label_y = (useY+packageObj.height/2+packageObj.y);
              if(placement.angle != 0) {
                const res = await dispatch('getCoordsForLabelIfPlacementWithAngle',{
                  x_placement:useX,
                  y_placement:useY,
                  x_label:label_x,
                  y_label:label_y,
                  placement_angle:placement.angle
                });
                label_x = res.x;
                label_y = res.y;
              }

              const plLabelIndex = state.placements.labels.findIndex((el:any)=>el.plId === placement.id);

              state.placements.labels[plLabelIndex] = {
                plId: placement.id,
                id: `textFor${placement.id}`,
                style:`transform: rotateX(180deg);`,
                'transform-origin':`${useX} ${useY}`,
                x:label_x,
                y:label_y,
                text:placement.refDesign,
              }
            }
          }
        }
      }
    },
    convertIntoCorrectUnits({ getters, rootGetters},payload){
      const {value,reverse} = payload;
      const boardFilesUnits = getters.stackup?.units; // mm or in
      const boardSettingsUnits = rootGetters.productSettings?.unitsIn; // metric or imperial
      // console.log(boardFilesUnits,boardSettingsUnits,value);
      if(boardFilesUnits === 'in' && boardSettingsUnits === 'metric') return reverse? value * 25.4 : value / 25.4;
      if(boardFilesUnits === 'mm' && boardSettingsUnits === 'imperial') return reverse? value / 25.4 : value * 25.4;

      return value;
    },
    async insertPartsPackages({state, getters, rootGetters, dispatch}) {
      console.log('start entering into insertPartsPackages');
      if(rootGetters.placements.length === 0) return;
      if(typeof state.stackup === 'string' ) return;
      if(!getters.stackup?.width) return;
      const isMetricFile = getters.stackup?.units === 'mm';
      const side:string = getters.selectedLayer;

      const bottom_top_flag = state.board.selectedLayer === 2 || state.board.selectedLayer === 3;

      if(!state.board.drawPackages || !bottom_top_flag) return;

      const placements:Placement[] = rootGetters.placements.filter((el:Placement)=>el.side?.toLowerCase() === side?.toLowerCase());
      const placementsWithPackages:Placement[] = placements.filter((el:any)=>el.package && !el.dnp);
      const placementsWithoutPackages:Placement[] = placements.filter((el:any)=>!el.package && !el.dnp);
      const placementsWithDnp:Placement[] = placements.filter((el:any)=>el.dnp);
      const packageSet = new Map(placementsWithPackages.map((item:Placement) => [item.package.id, item.package]));
      const uniquePackages:Package[] = [...packageSet.values()];

      const formatCoord = (el:number)=>parseFloat((el*1000).toFixed(3));

      state.placements.packages = [];
      state.placements.defs = [];
      state.placements.labels = [];
      state.placements.noPackages = [];
      state.placements.dnp = [];

      for (let i = 0; i < uniquePackages.length; i++) {
        const packageObj:Package = uniquePackages[i];

        let packageWidth = packageObj.maxX-packageObj.minX;
        let packageHeight = packageObj.maxY-packageObj.minY;
        let packageMinX = packageObj.minX;
        let packageMinY = packageObj.minY;

        if(!isMetricFile) {
          packageWidth /= 25.4;
          packageHeight /= 25.4;
          packageMinX /= 25.4;
          packageMinY /= 25.4;
        }

        const defWidth = formatCoord(packageWidth);
        const defHeight = formatCoord(packageHeight);

        const defX = formatCoord(packageMinX)//(p.package.minX)*1000+sideObj.viewBox[0]*2;
        const defY = formatCoord(packageMinY)//(p.package.minY)*1000+sideObj.viewBox[1]*2;

        state.placements.defs.push({
          i:packageObj.id,
          x:defX,
          y:defY,
          width:defWidth,
          height:defHeight,
          href:packageObj.imgLink
        });
      }
      for (let i = 0; i < placementsWithPackages.length; i++) {
        const p = placementsWithPackages[i];
        let useY = p.y;//+100;
        let useX = p.x;//-100;

        useY = await dispatch('convertIntoCorrectUnits', {
          value: useY,
          reverse:false
        });
        useX = await dispatch('convertIntoCorrectUnits', {
          value: useX,
          reverse:false
        });

        useY = formatCoord((useY));
        useX = formatCoord((useX));

        // product
        if (side.toLowerCase() === 'bottom') {
          useX = (getters.stackup.width * 1000) - useX + (getters.stackup.viewBox[0] * 2);
        }

        const forRotate = p.angle != 0 ? `rotate(${p.angle}deg)` : ''; //, ${defX + defWidth/2}, ${defY + defHeight/2}

        state.placements.packages.push({
          plId: p.id,
          id: `renderFor${p.id}`,
          style: `transform: ${forRotate} scaleY(-1); transform-origin: ${useX}px ${useY}px;`,
          y: useY,
          x: useX,
          href: `#defPlace${p.package.id}`,
        });

        if(state.board.showLabels) {
          const packageObj = state.placements.defs.find((el:any)=>el.i === p.package.id);
          if(!packageObj) continue;
          let label_x = (useX+packageObj.width/2+packageObj.x);
          let label_y = (useY+packageObj.height/2+packageObj.y);
          if(p.angle != 0) {
            const res = await dispatch('getCoordsForLabelIfPlacementWithAngle',{
              x_placement:useX,
              y_placement:useY,
              x_label:label_x,
              y_label:label_y,
              placement_angle:p.angle
            });
            label_x = res.x;
            label_y = res.y;
          }

          state.placements.labels.push({
            plId: p.id,
            id: `textFor${p.id}`,
            style:`transform: rotateX(180deg);`,
            'transform-origin':`${useX} ${useY}`,
            x:label_x,
            y:label_y,
            text:p.refDesign,
          });
        }
      }

      if(state.board.showLabels) {
        for (let i = 0; i < placementsWithDnp.length; i++) {
          const p = placementsWithDnp[i];
          let useY = p.y;
          let useX = p.x;

          useY = await dispatch('convertIntoCorrectUnits', {
            value: useY,
            reverse:false
          });
          useX = await dispatch('convertIntoCorrectUnits', {
            value: useX,
            reverse:false
          });

          // if (rootGetters.productSettings?.unitsIn === 'metric' && !isMetricFile) {
          //   useY /= 25.4;
          //   useX /= 25.4;
          // }
          useY = formatCoord(useY);
          useX = formatCoord(useX);

          if (side.toLowerCase() === 'bottom') {
            useX = (getters.stackup.width * 1000) - useX + (getters.stackup.viewBox[0] * 2);
          }

          const packageObj = state.placements.defs.find((el:any)=>el.i === p.package?.id);
          if(packageObj !== undefined) {
            state.placements.dnp.push({
              plId: p.id,
              id: `renderFor${p.id}`,
              style:`transform: scaleY(-1);`,
              'transform-origin':`${useX} ${useY}`,
              x:(useX+packageObj.width/2+packageObj.x),
              y:(useY+packageObj.height/2+packageObj.y),
              text:p.refDesign,
            })
          } else {
            state.placements.dnp.push({
              plId: p.id,
              id: `renderFor${p.id}`,
              style:`transform: scaleY(-1);`,
              'transform-origin':`${useX} ${useY}`,
              x:useX,
              y:useY,
              text:p.refDesign,
            })
          }

        }
      }

      for (let i = 0; i < placementsWithoutPackages.length; i++) {
        const r = placementsWithoutPackages[i];
        let useY = formatCoord(r.y);
        let useX = formatCoord(r.x);

        // if (rootGetters.productSettings?.unitsIn === 'metric' && !isMetricFile) {
        //   useY /= 25.4;
        //   useX /= 25.4;
        // }

        useY = await dispatch('convertIntoCorrectUnits', {
          value: useY,
          reverse:false
        });
        useX = await dispatch('convertIntoCorrectUnits', {
          value: useX,
          reverse:false
        });

        if(side.toLowerCase() === 'bottom'){
          useX = (getters.stackup.width*1000)-useX+(getters.stackup.viewBox[0]*2);
        }

        const forRotate = r.angle!==0?`rotate(${r.angle}deg)`:'';

        state.placements.noPackages.push({
          refDesign:r.refDesign,
          plId:r.id,
          id:'renderFor'+r.id,
          x:useX,
          y:useY,
          style:`transform: ${forRotate} scaleY(-1); transform-origin: ${useX}px ${useY}px;`,
        });
      }

      console.log('packages updated',state.placements);
    },
    findSelectableElements({state, getters}) {
      console.log('findSelectableElements');
      const side = getters.selectedLayer.toLowerCase();
      if(typeof state.stackup === 'string') {
        return state.selectableElements = [];
      }
      const stackup = state.stackup as Stackup;
      // console.log(stackup, side)
      if(stackup?.layers) {
        const selected_layer = stackup.layers.find((el:any)=>el.type === 'soldermask' && el.side === side);

        let rects = selected_layer?.converter.defs.filter((el:any)=>el.includes('rect') || el.includes('polygon') || el.includes('path') || el.includes('circle'));

        if(!rects) {
          return state.selectableElements = [];
        }

        rects = [
          ...rects.filter((el:any)=>el?.includes('polygon')),
          ...rects.filter((el:any)=>!el?.includes('polygon')),
        ]

        const selectableRects = [];
        const side_stackup = stackup[side as keyof Stackup] as SideStackup
        for (let i = 0; i < rects.length; i++) {
          const rect = rects[i];
          const rect_obj = new DOMParser().parseFromString(rect, "text/html").body.children[0];

          const filtered_layers = selected_layer?.converter.layer
            .filter((el:any)=>el.includes(rect_obj.id))
          if(!filtered_layers) {
            continue
          }
          const usesObj = []

          for (let j = 0; j < filtered_layers.length; j++) {
            const el = filtered_layers[j]
            const obj = new DOMParser().parseFromString(el, "text/html").body.children[0];
            const x_str = obj.getAttribute('x');
            if(typeof x_str !== 'string') return {x:0,y:0};
            let x = parseFloat(x_str);
            if(side == 'bottom'){
              x = (side_stackup.width*1000)-x+(side_stackup.viewBox[0]*2);
            }
            usesObj.push({
              id:`s_e_${rect_obj.id}_${j}`,
              x:x,
              y:obj.getAttribute('y'),
            })
          }
          if(rect_obj) {
            // console.log(rect_obj)
            selectableRects.push({
              id:rect_obj.id,
              x:rect_obj.getAttribute('x'),
              y:rect_obj.getAttribute('y'),
              width:rect_obj.getAttribute('width'),
              height:rect_obj.getAttribute('height'),
              usesObj
            })
          }
        }
        // console.log(selectableRects);
        return state.selectableElements = selectableRects;
      }
      return state.selectableElements = [];
    }
  }
}
