import {PartCategoryTab} from "@/components/ExperimParts/types";

const resistanceTab:PartCategoryTab = {
  columns:[
    { key: "manufacturer", label: "Manufacturer", sortable: false, width: 200 },
    { key: "part_number", label: "Part Number", sortable: false,thAlign: "start", width: 200  },
    { key: "resistance", label: "Resistance", sortable: false, width: 120  },
    { key: "tolerance", label: "Tolerance", sortable: false, width: 120  },
    { key: "package", label: "Package", sortable: false, width: 200  },
    { key: "qty", label: "Qty on Hand", sortable: false },
    { key: "locs", label: "Locations", sortable: false },
    { key: "price", label: "Price", sortable: false },
  ],
  packages_ids:[50,78]
}

export {resistanceTab, PartCategoryTab}
