import {PartCategoryTab} from "@/components/ExperimParts/ResistanceTabFilters/ResistanceTable";

const diodesTable:PartCategoryTab = {
  columns: [
    { key: "manufacturer", label: "Manufacturer", sortable: false, width: 200  },
    { key: "part_number", label: "Part Number", sortable: false,thAlign: "start", width: 200  },
    { key: "d_voltage", label: "Voltage - DC Reverse (Vr) (Max)", sortable: false, width: 120  },
    { key: "d_current", label: "Current - Average Rectified (Io)", sortable: false, width: 120  },
    { key: "package", label: "Package", sortable: false, width: 200  },
    { key: "qty", label: "Qty on Hand", sortable: false },
    { key: "locs", label: "Locations", sortable: false },
    { key: "price", label: "Price", sortable: false },
  ],
  packages_ids: [20,22]
}

export {diodesTable}
