import { Module } from "vuex";
import { RootState } from "../types";
import axios from "axios";
import {ProductState} from "@/store/products/types";
import router from '@/router/index';
import {useToast} from "vuestic-ui";

export const Products:Module<ProductState, RootState> = {
  state: {
    selTabMirror:'',
    product: {
      id:-1,
      partNumber:'',
      name:'',
      imgLink:'',
      visible:true,
      job: '',
      notes:'',
      description:'',
    },
    orders: [],
    files: [],
    boardFiles: [],
    files_types: [],
    boms: [],
    placements:[],
    settings: null,
    boardSettingsOptions:{
      layersNumbersOptions:[],
      boardThicknessOptions:[],
      panel_cutting_options:[],
      edgeRailsOptions:[],
      options_via_process:[],
      coopers:[],
      options:[], //////////////
      colorsForSilk:[],
      colorsForMask:[],
      unitsOptions:[],
      boardMaterialsOptions:[],
    },
    isLoading:false,
    isAdminView:false,
    boardFilesTypesOrdered: [
      'topSilk',
      'topMask',
      'topCopper',
      'topPaste',
      'internalLayer',
      'bottomPaste',
      'bottomCopper',
      'bottomMask',
      'bottomSilk',
      'platedDrill',
      'nonPlatedDrill',
      'otherAttachment',
      'routing',
      'outline'
    ]
  },
  mutations: {
    changeSelTabMirror(state,data){
      state.selTabMirror = data;
    },
    toggleAdminView(state){
      state.isAdminView = !state.isAdminView;
    },
    updatePickAndPlace(state,data){
      state.placements = data;
    },
    clearData(state){
      state.product = {
        id:-1,
        partNumber:'',
        name:'',
        imgLink:'',
        visible:true,
        job: '',
        notes:'',
        description:'',
      },
      state.files = [],
      state.boardFiles = [],
      state.files_types = [],
      state.boms = [],
      state.settings = null,
      state.isLoading = false
    },
    clearProductPageData(state){
      state.product = {
        id:-1,
        partNumber:'',
        name:'',
        imgLink:'',
        visible:true,
        job: '',
        notes:'',
        description:'',
      },
      state.files = [];
      state.boardFiles = [];
      state.files_types = [];
      state.boms = [];
      state.settings = null;
      state.isLoading = false
    },
    startLoadingProductInfo(state){
      state.isLoading = true;
    },
    stopLoadingProductInfo(state){
      state.isLoading = false;
    },
    updateSettingsOptions(state,data){
      state.boardSettingsOptions = data;
    },
    updateFiles(state,Files){
      state.files = Files;
    },
    updateProduct(state,Product){
      state.product = Product;
    },
    updateFilesTypes(state,types){
      state.files_types = types;
    },
    updateProductSettings(state,settings){
      state.settings = settings;
    },
    updateBoardFiles(state,files){
      state.boardFiles = files;
    },
    updateBOMRecords(state,records){
      state.boms = records;
    },
    updateOrders(state,data){
      state.orders = data;
    }
  },
  getters: {
    getSelTabMirror(state){
      return state.selTabMirror
    },
    boardFilesTypesOrdered(state){
      return state.boardFilesTypesOrdered
    },
    boardSettingsOptions(state){
      return state.boardSettingsOptions
    },
    isAdminView(state){
      return state.isAdminView;
    },
    productOrders(state){
      return state.orders;
    },
    bomInfo(state){
      let confirmedAmount = 0;
      // state.boms!.filter(el=>el.isItemSelectedBefore == false && el.custWillSupply == false && el.refDesign.filter(el2=>el2.doNotPopulate).length != el.refDesign.length);
      // if(this.partNumber != '' && this.bom.itemSource && this.bom.itemRef ) {
      //   if(this.bom.itemSource != '' && this.bom.itemRef != ''){
      //     return true;
      //   }
      // }
      state.boms!.map(el => {
        let flag = false;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if(el.part_id != null || el.confirmed_by != null || el.substitutions_rows.length > 0 || !el.refDesign.find(el=>!el.doNotPopulate)) {
            flag = true;
        }

        if(el.custWillSupply) flag = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if(el.refDesign.filter(el2=>el2.doNotPopulate).length == el.refDesign.length) flag = true;
        if(flag) {
          confirmedAmount++;
        }
      });
      return {
        show:true,
        totalAmount:state.boms!.length,
        confirmed:confirmedAmount,
      };
    },
    placementsInfo(state){
      return {
        show:true,
        totalAmount:state.placements.length,
        confirmed:state.placements.filter(el=>el.confirmed).length,
      };
    },
    placements(state){
      return state.placements.filter(el=>{
        return el.refDesign != null && el.refDesign != ''
      });
    },
    boms(state){
      return state.boms;
    },
    boardFilesForParser(state){
      return state.boardFiles;
    },
    isProductLoading(state){
      return state.isLoading;
    },
    productBoardFiles(state){
      return state.boardFiles.sort((el1,el2)=>{
        const types = state.boardFilesTypesOrdered;
        const first = types.findIndex(el=>{
          return el == el1.file_type
        });
        const second = types.findIndex(el=>{
          return el == el2.file_type
        });

        if(el1.file_type === 'internalLayer' && el2.file_type === 'internalLayer') {
          return el1.layer_number-el2.layer_number;
        }
        return first-second;
      });
    },
    productFiles(state){
      return state.files;
    },
    filesTypes(state){
      return state.files_types;
    },
    productInfo(state){
      return state.product;
    },
    productSettings(state){
      return state.settings;
    },
    placeFilesForParsing(state){
      const placeRecords = state.placements;
      return state.files!.filter(el=>{
        const firstRule = el.type_id==5;
        const secondRule = !placeRecords.find(el2=>el2.product_file_id == el.id);
        return (secondRule && firstRule);
      });
    },
    bomFilesForParsing(state){
      const bomsRecords = state.boms;
      return state.files!.filter(el=>{
        const firstRule = el.type_id==4;
        const secondRule = !bomsRecords!.find(el2=>el2.product_file_id == el.id);
        return (secondRule && firstRule);
      });
    },
    allRefsForPlacement(state){
      const founded = [];
      const existedRefs = state.placements.map(el=>{
        if(typeof el.refDesign == 'string') {
          return el.refDesign.toLowerCase().replace(/\s/g, '')
        }
        return el.refDesign
      });

      for (let i = 0; i < state.boms!.length; i++) {
        const bom = state.boms![i];
        if(bom.refDesign.length>0 ){
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const refs = [];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          bom.refDesign.map(el=>{
            if(!existedRefs.includes(el.refDesign.toLowerCase().replace(/\s/g, ''))) {
              refs.push(el.refDesign)
            }
          });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          founded.push(...refs);
        }
      }
      return [...new Set(founded)];
    }
  },
  actions: {
    async savePriceSuggestionResult({ dispatch, rootState },{
      dataForSaving,
      productId,
    }) {
      const response = await axios.post(`${rootState.apiUri}/api/products/${productId}/bom/saveSuggestResults`,dataForSaving);
      if(response.data.error) useToast().init({message: response.data.message, color: 'danger'})
      dispatch('loadProduct',productId);
    },
    async sendEmailBoardReport({ dispatch, rootState },{
      productId,
      email
    }){
      const respone = await axios.get(`${rootState.apiUri}/api/products/${productId}/sendReport?token=${this.state.token}&email=${email}`);
      return respone;
    },
    async addPlaceItem({ dispatch, rootState },data:any){
      const {productId} = data;
      await axios.post(`${rootState.apiUri}/api/products/${productId}/place/add`, data );
      dispatch('loadProduct',productId).then(()=>{
        dispatch('BoardInfo/insertPartsPackages');
      });
    },
    async addPlaceFronmFile({ dispatch, rootState },{
      data,
      file_id,
      prodId
    }){
      //console.log(data,prodId);
      const sendData = {
        data
      }
      if(file_id){
        // eslint-disable-next-line
        // @ts-ignore
        sendData.file_id = file_id;
      }
      await axios.post(`${rootState.apiUri}/api/products/${prodId}/place/fromFile`, sendData, );
      dispatch('loadProduct',prodId).then(()=>{
        dispatch('BoardInfo/insertPartsPackages');
      });
    },
    async addBOMsFromFile({ dispatch, rootState },{
      data,
      file_id,
      prodId
    }){
      //console.log(data,prodId);
      const sendData = {
        data
      }
      if(file_id){
        // eslint-disable-next-line
        // @ts-ignore
        sendData.file_id = file_id;
      }
      await axios.post(`${rootState.apiUri}/api/products/${prodId}/bom/fromFile`, sendData, {
        // headers: {
        //   'Content-Type': 'multipart/form-data'
        // }
      });
      // dispatch('loadProduct',prodId);
    },
    async parseExistedFile({ dispatch, rootState },{
      productId,
      file
    }){
      const formData = new FormData();
      formData.append('file_id',file);
      const response = await axios.post(`${rootState.apiUri}/api/products/${productId}/bom/parseExisted`, formData);
      if(response.data.error){
        console.error('Something wrong',response);
      } else {
        console.log(response.data);
        return response.data.parsed
      }
      return false;
    },
    async parseBomCsv({ dispatch, rootState },{
      productId,
      file
    }){
      const formData = new FormData();
      formData.append('table',file);
      const response = await axios.post(`${rootState.apiUri}/api/products/${productId}/bom/parse`, formData, );
      if(response.data.error){
        console.error('Something wrong',response);
      } else {
        console.log(response.data);
        return response.data.parsed
      }
      return false;
    },
    async addBomItem({ dispatch, rootState },{
      refDesign,
      partNumber,
      productId
    }){
      //console.log(refDesign,partNumber,productId);
      await axios.post(`${rootState.apiUri}/api/products/${productId}/bom/add`, {
        refDesign,
        partNumber
      }, );
      dispatch('loadProduct',productId);
    },
    async removeBom({ dispatch, rootState },{
      bomId,
      productId
    }){
      await axios.delete(`${rootState.apiUri}/api/products/${productId}/bom/${bomId}`, );
      dispatch('loadProduct',productId);
    },
    async savePlacementsMulti({ dispatch, rootState },{
      placements,
      productId
    }){
      const res = await axios.post(`${rootState.apiUri}/api/products/${productId}/place/update`, {
        placements
      });
      dispatch('loadProduct',productId).then(()=>{
        dispatch('BoardInfo/insertPartsPackages');
      });
    },
    async updatePlace({ dispatch, rootState },{
      data,
      productId,
      placeId
    }){
      await axios.post(`${rootState.apiUri}/api/products/${productId}/place/${placeId}`, data, );
      dispatch('loadProduct',productId).then(()=>{
        dispatch('BoardInfo/insertPartsPackages');
      });
    },
    async removePlace({ dispatch, rootState },{
      placeId,
      productId
    }){
      await axios.delete(`${rootState.apiUri}/api/products/${productId}/place/${placeId}`, );
      await dispatch('loadProduct',productId).then(()=>{
        dispatch('BoardInfo/insertPartsPackages');
      });
    },
    async removePlaceMulti({ dispatch, rootState },{
      ids,
      productId
    }){
      await axios.post(`${rootState.apiUri}/api/products/${productId}/place/delete`,{
        ids
      } );
      dispatch('loadProduct',productId).then(()=>{
        dispatch('BoardInfo/insertPartsPackages');
      });
    },
    async removeBomMulti({ dispatch, rootState },{
      bomIds,
      productId
    }){
      await axios.post(`${rootState.apiUri}/api/products/${productId}/bom/delete`,{
        ids:bomIds
      } );
      dispatch('loadProduct',productId);
    },
    async bomSaving({ dispatch, rootState },{
      data,
      settings,
      productId
    }){
      await axios.post(`${rootState.apiUri}/api/products/${productId}/bom/save`, {
        boms:data
      }, );
      if(settings){
        await axios.post(`${rootState.apiUri}/api/products/${productId}/saveSettings`, settings, );
      }
      await dispatch('loadProduct',productId);
      dispatch('BoardInfo/insertPartsPackages');
    },
    async updateBoardFileInfo({ dispatch, rootState },{
      data,
      productId
    }){
      console.log(data,productId);
      await axios.post(`${rootState.apiUri}/api/products/${productId}/updateBoardFiles`, {
        boardFiles:data
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if(data.length != 0 ) dispatch('loadProduct',productId);
    },
    async boardSaveBtn({ dispatch, rootState, rootGetters },{
      data,
      settings,
      productId
    }){
      if(data){
        if(data.length>0){
          await axios.post(`${rootState.apiUri}/api/products/${productId}/updateBoardFiles`, {
            boardFiles:data
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        }
      }
      if(rootGetters['BoardTab/isValidatedBoardSettingsTab']) {
        await axios.post(`${rootState.apiUri}/api/products/${productId}/saveSettings`, settings, );
      }
      await dispatch('loadProduct',productId);
    },
    removeFileFromBoard({ dispatch, rootState },{
      fileId,
      boardId,
      productId
    }){
      axios.delete(`${rootState.apiUri}/api/products/${productId}/files/${fileId}/boardFile/${boardId}`).then(_=>{
        dispatch('loadProduct',productId);
      })
    },
    async addFilesToTheBoard({ dispatch, rootState },{
      files,
      productId
    }){
      for (let i = 0; i < files.length; i++) {
        await axios.post(`${rootState.apiUri}/api/products/${productId}/files/${files[i]}/addToBoard`, );
      }
      dispatch('loadProduct',productId);
    },
    async saveProductSettings({ dispatch, rootState },{
      settings,
      productId
    }){
      await axios.post(`${rootState.apiUri}/api/products/${productId}/saveSettings`, settings, );
      dispatch('loadProduct',productId);
    },
    async deleteFile({ dispatch, rootState },{
      fileId,
      productId
    }){
      await axios.delete(`${rootState.apiUri}/api/products/${productId}/files/${fileId}`);
      dispatch('loadProduct',productId);
    },
    async updateFilesInfo({ dispatch, rootState },{
      files,
      productId
    }){
      await axios.post(`${rootState.apiUri}/api/products/${productId}/updateFiles`, {
        files
      }, );
      dispatch('loadProduct',productId);
    },
    // async updateFilesInfo({ dispatch, rootState },{
    //   filesInfos,
    //   productId
    // }){
    //   for (let i = 0; i < filesInfos.length; i++) {
    //     const formData = new FormData();
    //     formData.append('type_id',filesInfos[i].type_id);
    //     formData.append('description',filesInfos[i].description);
    //     await axios.post(`${rootState.apiUri}/api/products/${productId}/files/${filesInfos[i].id}/update`, formData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data'
    //       }
    //     });
    //   }
    //   dispatch('loadProduct',productId);
    // },
    async addFilesToProduct({ dispatch, rootState },{
      files,
      productId,
      isAutoDetect,
      isUnzipFlag = true,
      toast
    }){
      const formData = new FormData();
      formData.append('isAutoDetect',isAutoDetect);
      formData.append('isUnzipFlag',isUnzipFlag);
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]',files[i]);
      }
      try {
        const res = await axios.post(`${rootState.apiUri}/api/products/${productId}/addFiles`, formData );
        if(res.data.error){
          console.error('Files not added');
          // throw new Error(res.data.error);
        }
      } catch (e) {
        toast.init({message: 'Files not added', color: 'danger'})
        console.error('Files not added',e);
      } finally {
        dispatch('loadProduct',productId);
      }


    },
    async loadSettingsOptions({ commit, rootState }){
      const res = await axios.get('/api/getBoardSettingsOptions');

      commit('updateSettingsOptions',res.data);
    },
    async loadProduct({ commit, rootState },id){
      commit('startLoadingProductInfo');
      try {
        const res = await axios.get(rootState.apiUri+`/api/products/${id}`)
        if(res.data.error){
          console.error(res.data.error);
        } else {
          commit('updateFiles',res.data.files);
          commit('updateProduct',res.data.product);
          commit('updateFilesTypes',res.data.files_types);
          commit('updateProductSettings',res.data.settings);
          commit('updateBoardFiles',res.data.boardFiles);
          commit('updateOrders',res.data.orders);
          commit('updateBOMRecords',res.data.bom);
          commit('updatePickAndPlace',res.data.placements);
        }
      } catch(error){
        router.push('/projects')
      }
      commit('stopLoadingProductInfo');
    },
    updateProduct({ dispatch, rootState },{
      id,
      name,
      partNumber,
      cover,
      job,
      notes,
      description,
      odoo_products
    }){
      //
      // Create a separate payload for odoo_products if there are any
      // Two requests are needed because formData doesn't transfer JSON, and payload doesn't transfer file blob
      if(odoo_products){
        const payload = {
          name: name,
          odoo_products: odoo_products ?? [],
        };

        axios.post(`${rootState.apiUri}/api/products/${id}/update`, payload)
          .then(res => {
            if (res.data.error) {
              console.error('Product not updated');
            } else {
              //dispatch('loadProduct', id); // Reload the product data after update
            }
          })
          .catch(error => {
            console.error("Error during API request:", error);
          });
      }

      // Save the rest of the fields through FormData
      const formData = new FormData();
      formData.append('name',name);
      formData.append('partNumber',partNumber);
      formData.append('job',job ?? '');
      formData.append('notes',notes ?? '');
      formData.append('description',description ?? '');

      // Append cover image if it's a file
      if (cover instanceof File) {
        formData.append("cover", cover);
      }
      //if(cover) formData.append('cover',cover);
      axios.post(`${rootState.apiUri}/api/products/${id}/update`, formData, ).then(res=>{
        if(res.data.error){
          console.error('Product not updated');
        } else {
          dispatch('loadProduct',id);
        }
      });
    },
    async addProduct({ dispatch, rootState },{
      name,
      partNumber,
      projectId,
      cover,
      files,
      newProjectName,
      assemblyId
    }){
      const formData = new FormData();
      formData.append('name',name);
      formData.append('partNumber',partNumber);
      if(cover) formData.append('cover',cover);
      if (assemblyId) formData.append('assemblyId',assemblyId);
      if(newProjectName) formData.append('newProjectName',newProjectName);
      // console.log(files)
      if(files) {
        for (let i = 0; i < files.length; i++) {
          formData.append('files[]',files[i]);
        }
      }
      const path =  `${rootState.apiUri}/api/projects/${projectId}/products/add`
      const res = await axios.post(path, formData, );
      if(res.data.error){
        console.error('Product not added');
      } else {
        router.push({ name: 'product_view', params: { id: res.data.data.id }, query: { initTab: 'Files' } });
        // dispatch('loadProjects');
      }
    },
    updateProductVisibility({ dispatch, rootState }, {id, visible}){
      const formData = new FormData();
      formData.append('visible', visible);
      axios.post(`${rootState.apiUri}/api/products/${id}/updateVisibility`, formData,).then(res=>{
        if(res.data.error){
          console.error('Request executed with an error. Product not updated');
        } else {
          dispatch('loadProduct',id);
        }
      });
    },
    async duplicateProductRequest({ dispatch, rootState }, {id}){
      const formData = new FormData();
      const res = await axios.post(`${rootState.apiUri}/api/products/${id}/duplicate`, formData,);
      if(res.data.error){
        console.error('Request executed with an error. Product not duplicated');
      } else {
        window.open('/product/' + res.data.data.id,'_blank');
        // router.push('/product/' + res.data.data.id)
      }
    },
    async editPackage({ dispatch, rootState },data){
      let path = ``;
      if(data.id) {
        path = `${rootState.apiUri}/api/packages/${data.id}/update`;
        await axios.post(path, {
          mountType: data.mountType,
          numOfPins: data.numOfPins
        });
        dispatch('downloadCategories');
      }
    }
  },
}
