import {PartCategoryTab} from "@/components/ExperimParts/ResistanceTabFilters/ResistanceTable";

const ICTab:PartCategoryTab = {
  columns:[
    { key: "manufacturer", label: "Manufacturer", sortable: false, width: 200  },
    { key: "part_number", label: "Part Number", sortable: false,thAlign: "start", width: 200  },
    { key: "package", label: "Package", sortable: false, width: 200  },
    { key: "qty", label: "Qty on Hand", sortable: false },
    { key: "locs", label: "Locations", sortable: false },
    { key: "price", label: "Price", sortable: false },
  ],
  packages_ids: [ 25, 39, 37, 61, 50, 78, 38 ]
}

export {ICTab, PartCategoryTab}
