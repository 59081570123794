import {RouteRecordRaw} from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: false,
      title: 'Circuits',
    },
    component: () => import(/* webpackChunkName: "homeView" */ '@/views/HomeView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue'),
    meta: {
      requiresAuth: false,
      title: 'About',
    },
  },
  {
    path: '/product/:id',
    name: 'product_view',
    component: () => import(/* webpackChunkName: "productView" */ '@/views/ProductView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Product',
    },
  },
  {
    path: '/order/:id',
    name: 'order_view',
    component: () => import(/* webpackChunkName: "orderView" */ '@/views/OrderView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Order',
    },
  },
  {
    path: '/board/:id',
    name: 'board_test',
    component: () => import(/* webpackChunkName: "boardView" */ '@/views/BoardView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Board',
    },
  },
  {
    path: '/projects',
    redirect: '/workspace',
    name: 'projects',
    meta: {
      requiresAuth: true,
      title: 'Projects',
    },
  },
  {
    path: '/workspace',
    name: 'workspace',
    component: () => import(/* webpackChunkName: "workspaceView" */ '@/views/WorkspaceView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Workspace',
    },
  },
  // {
  //   path: '/cart',
  //   name: 'cart',
  //   component: () => import(/* webpackChunkName: "cartView" */ '@/views/CartView.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/createOrder/:id/',
  //   name: 'createOrder',
  //   // component: CreateOrderView,
  //   component: () => import(/* webpackChunkName: "createOrderView" */ '@/views/CreateOrderView.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/cabinet',
    name: 'cabinet',
    // component: CabinetView,
    component: () => import(/* webpackChunkName: "cabinetView" */ '@/views/CabinetView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Cabinet',
    },
  },
  {
    path: '/cabinet_new',
    name: 'cabinet_new',
    // component: CabinetView,
    component: () => import(/* webpackChunkName: "cabinetView" */ '@/views/NewCabinetView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Cabinet',
    },
  },
  {
    path: '/login',
    name: 'login',
    // component: LoginView,
    component: () => import(/* webpackChunkName: "loginView" */ '@/views/LoginView.vue'),
    meta: {
      requiresAuth: false,
      title: 'Login Page',
    },
  },
  {
    path: '/register',
    name: 'register',
    // component: RegisterView,
    component: () => import(/* webpackChunkName: "registerView" */ '@/views/RegisterView.vue'),
    meta: {
      requiresAuth: false,
      title: 'Register Page',
    },
  },
  {
    path: '/confirmEmail',
    name: 'confirmEmail',
    // component: ConfirmEmail,
    component: () => import(/* webpackChunkName: "confirmEmail" */ '@/views/ConfirmEmail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Confirm Email',
    },

  },
  {
    path: '/parts',
    name: 'parts',
    // component: PartsPage,
    component: () => import(/* webpackChunkName: "partsPage" */ '@/views/Parts.vue'),
    meta: {
      requiresAuth: true,
      title: 'Parts',
    },
  },
  {
    path: '/experimental_parts',
    name: 'experimental_parts',
    // component: ExperimentalPartsSearch,
    component: () => import(/* webpackChunkName: "experimentalPartsSearch" */ '@/views/ExperimentalPartsSearch.vue'),
    meta: {
      requiresAuth: true,
      title: 'Parts Search',
    },
  },
  {
    path: '/orders',
    name: 'orders',
    // component: OrdersView,
    component: () => import(/* webpackChunkName: "ordersView" */ '@/views/OrdersView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Orders',
    },
  },
  {
    path: '/payment/:doc_id',
    name: 'payment',
    // component: PaymentView,
    component: () => import(/* webpackChunkName: "paymentView" */ '@/views/PaymentView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Payment',
    },
  },
  {
    path: '/paypal_payment',
    name: 'paypal_payment',
    // component: PayPalPayment,
    component: () => import(/* webpackChunkName: "payPalPayment" */ '@/views/PayPalPayment.vue'),
    meta: {
      requiresAuth: true,
      title: 'PayPal Payment',
    },
  },
  {
    path: '/part/:id/',
    name: 'part',
    // component: PartPage,
    component: () => import(/* webpackChunkName: "part" */ '@/views/Part.vue'),
    meta: {
      requiresAuth: true,
      title: 'Part',
    },
  },
  {
    path: '/packages',
    name: 'package',
    // component: PackageView,
    component: () => import(/* webpackChunkName: "packageView" */ '@/views/PackageView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Packages',
    },

  },
  {
    path: '/manufacturers',
    name: 'manufacturers',
    // component: ManufacturersView,
    component: () => import(/* webpackChunkName: "manufacturersView" */ '@/views/ManufacturersView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Manufacturers',
    },
  },
  // {
  //   path: '/xtupleCheck',
  //   name: 'xtupleCheck',
  //   // component: XtupleCheckView,
  //   component: () => import(/* webpackChunkName: "xtupleCheckView" */ '@/views/XtupleCheckView.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/createDocument/:type/:id',
    name: 'createDocument',
    // component: CreateDocumentView,
    component: () => import(/* webpackChunkName: "createDocumentView" */ '@/views/CreateDocumentView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Create Document',
    },
  },
  {
    path: '/document/:type/:id',
    name: 'document',
    // component: CreateDocumentView,
    component: () => import(/* webpackChunkName: "createDocumentView" */ '@/views/CreateDocumentView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Document',
    },
  },
  {
    path: '/adminPanel',
    name: 'adminPanel',
    // component: AdminPanelView,
    component: () => import(/* webpackChunkName: "adminPanelView" */ '@/views/AdminPanelView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Admin Panel',
    },
  },
  {
    path: '/instantQuote',
    name: 'instantQuote',
    component: () => import(/* webpackChunkName: "instantQuoteView" */ '@/views/InstantQuoteView.vue'),
    meta: {
      requiresAuth: false,
      title: 'Instant Quote',
    },
  },
  {
    path: '/createOutline/:product_id',
    name: 'createOutline',
    component: () => import(/* webpackChunkName: "instantQuoteView" */ '@/views/СreateOutlineView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Create Outline',
    },
  }
]

export default routes;
